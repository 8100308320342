import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { Modal, Spin } from 'antd';
import { FilterPackages } from '../Hotels/FilterSection';
import Select from 'react-select'
import { useSelector } from 'react-redux';



const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function PkgList() {
  const navigate = useNavigate();

  const [state, setState] = useState({ cID: '', month: [], location: '', categoryId: [] })
  const [page, setPage] = useState(1)
  const [search1, setSearch1] = useState('')
  const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([]);
  const [list, setList] = useState([])
  const [list1, setList1] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [reload, setReload] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [selectedValues, setSelectedValues] = useState([100, 100000]);
  const [open1, setOpen1] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const user = useSelector(reducer => reducer.data?.info)


  const elementRef = useRef(null);


  useEffect(() => {
    getList2();
  }, []);

  const getList2 = () => {
    // setIsLoading(true)
    CommonAPI({}, 'GET', `countryList`, responseBack2)
  }

  const responseBack2 = useCallback(res => {
    if (res.status === true) {
      const dt = res?.data
      setCountries(dt)
    } else {
    }
  })

  useEffect(() => {
    const selected = localStorage.getItem('searchParams');
    const states = JSON.parse(selected);

    const stateVal = (states?.cID == '' || states == null) ? state : states

    setState(stateVal)
    getList(stateVal)
  }, [page, reload, search1]);



  const getList = (states) => {
    const data = localStorage.getItem('searchParams');

    setIsLoading(true)
    CommonAPI({}, 'GET', `topTagsList?type=country&typeId=${Number(states.digit) || ''}`, responseBack3)
    CommonAPI({}, 'GET', `packageList?countryId=${states.cID || ''}&location=${states.location || ''}&query=${search1 ? search1 : ''}&pricefilter=${selectedValues[1] == '' || selectedValues.length == 0 ? JSON.stringify([100, 220000]) : JSON.stringify(selectedValues)}&month=${states.month.length ? JSON.stringify(states.month) : ""}&categoryId=${states.categoryId?.length > 0 ? JSON.stringify(states.categoryId) : []}&page=${page}`, responseBack)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)
      const data = dec1?.packagefilter
      setLoadMore(true);

      setList1(dec1?.locations)
      if (data == undefined || data == null) {
        setList(data)
      } else {
        setList(prevData => {
          const newData = data.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
          return [...prevData, ...newData];
        });
      }

      if (data.length < 12) {
        setLoadMore(false);
      }

    } else {
    }
    setIsLoading1(false)
    setIsLoading(false)
  })

  const responseBack3 = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res?.data, key)
      setTagsList(dec1)
    } else {
    }
    // setIsLoading(false)
  })


  const handleShowMore = () => {
    setIsLoading1(true)
    setPage(prevPage => prevPage + 1)
  };

  const handleCheckboxChange = (value, type, type1) => {
    setList([])
    setPage(1)
    const rn = Math.floor(Math.random() * 9999 + 1)
    setReload(rn)

    if (type == 'rng') {
      setSelectedValues(value)
    } else if (type1 == 'other') {
      setState(prevValues => {
        const prev = prevValues?.[type] || []
        if (prev.includes(value)) {
          const newState = {
            ...prevValues,
            [type]: prev.filter(item => item !== value)
          }

          localStorage.setItem('searchParams', JSON.stringify(newState));
          return newState
        } else {
          const newState = {
            ...prevValues,
            [type]: [...prev, value]
          }
          localStorage.setItem('searchParams', JSON.stringify(newState));
          return newState
        }
      });
    } else {

      setState(prevValues => {
        const resetMonth = type == 'cID' ? { month: [], location: '' } : null

        const newState = {
          ...prevValues,
          ...resetMonth,
          [type]: value == 'TL' ? '' : value
        }

        localStorage.setItem('searchParams', JSON.stringify(newState));
        return newState
      });
    }
  };


  const data = localStorage.getItem('searchParams');
  const stateVal = JSON.parse(data);

  const customStyle = {
    placeholder: (provied) => ({
      ...provied,
      fontSize: '12px'
    }),
    singleValue: (provied) => ({
      ...provied,
      fontSize: '12px'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    menuPortal: base => ({ ...base, zIndex: 98889999, })
  }

  const options = countries.map((item) => ({
    value: item._id,
    digit: item.id,
    label: item.name
  }))



  const handleOk = (ty) => {
    setTimeout(() => {
      setOpen1(false);
    }, 1000);
  };

  const openAbout = (name, id, idLong, type) => {
    const slug = name.replace(/ /g, '-')

    navigate(`/${type}/${slug}/${id}`, { state: { id: idLong, type: type } })
  }




  return (
    <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center' style={{ backgroundColor: '#eef4fd' }}>

      <div className='w_92 bg-warning1 mt-4 mb-5'>

        <Row className='bg-primary1 mt-4' ref={elementRef}>
          <Col xs={0} sm={0} md={5} lg={4} xl={3} className='d-none-on-lg h-auto'>
            <FilterPackages selectedValues={stateVal} locations={list1 || []} country={country} onChange={handleCheckboxChange} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={9} className='bg-primary1 pt-0 px-0 d-flex justify-content-start align-items-center flex-column'>
            <div className='bg-white d-flex align-items-center flex-column flex-sm-row rd_6 p-3 w_97s mx-0-7 mb-4'>
              <div className='bg-primary11 p-0 me-sm-2 flex-grow-1 w-md-100 mb-xs1-20'>
                <InputGroup className=''>
                  <InputGroup.Text className='input-group-text pointer h_40px'>
                    <i className='fa fa-search text_gray'></i>
                  </InputGroup.Text>
                  <FormControl
                    className='search-input text_gray fs_13 rd_tr_br_6 px-2 h_40px'
                    type='text'
                    placeholder='Search'
                    style={{ height: 35, color: 'red' }}
                    value={search1}
                    onChange={(event) => { setList([]); setSearch1(event.target.value); setPage(1) }}
                  />
                </InputGroup>
              </div>
              <div className='bg-warning1 d-flex w-md-100'>
                <div className='h-100 p-0 w_150px w-md-200px w-md-100 me-2'>
                  <Select
                    options={options}
                    value={options.find(val => val.value === stateVal?.cID)}
                    placeholder='Select Country'
                    styles={customStyle}
                    classNamePrefix="custom-select h-100"
                    menuPortalTarget={document.body}
                    onChange={(val) => { handleCheckboxChange(val.value, 'cID'); handleCheckboxChange(val.digit, 'digit'); setCountry(val.label); }} />
                </div>
                <div className='d-show-on-mdl'>
                  <p className='Source_Medium text-white p-1 px-3 rd_6 fs_14 bg-gradient-thm d-flex align-items-center justify-content-center pointer m-0 h_40px' onClick={() => setOpen1(true)}>FILTER <i className='bi bi-filter '></i></p>
                </div>
              </div>
            </div>


            {isLoading && (
              <div className='d-flex justify-content-center mb-4'>
                <Spin className='thm-spin' />
              </div>
            )}

            <Row className='bg-warning1 d-flex justify-sm-center w-100'>
              {/* Listing */}
              {list && list.map((item, i) => {
                const lowPrice = item.discountPrice
                const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                const vendor = item?.vendorDetails
                return (
                  <Col xs={6} sm={12} lg={6} xl={4} xxl={4} key={i} className="xmd-6 m-xxs-auto mb-4 w-xxs-99 bg-success1 px-2 px-xs-0">
                    <div className="package m-1 mt-0 position-relative rd_10 bg-white mb-xs-20 overflow-hidden h_230px1 pkg-btn-hover">
                      <Link target='_blank' to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                        <img src={`${img_base_url + item.image}`} className='img-fluid mty-image h_150px w-100 position-relative overflow-hidden' />
                        <div className='p-1 px-2-5' style={{
                          position: 'absolute',
                          top: 0,
                          width: 'auto',
                          height: 'auto',
                          borderBottomRightRadius: 10,
                          background: 'rgba(0,0,0,0.3)'
                        }}>
                          <p className='Source_Regular text-white fs_11 m-0 text-capitalize'>{item.duration}</p>
                        </div>


                        <div className="p-3 overflow-hidden position-relative">
                          <h3 className='Rubik-SemiBold text_gray fs_13 spacing text-capitalize mini-line-1 m-0'>{item.name}</h3>
                          <p className='Rubik-Regular fs_11 text_gray2 mini-line-1 m-0'>{item?.category?.name}</p>


                          <div className='d-flex'>
                            <p className='Source_Regular text_gray fs_12 m-0 text-capitalize'>By {vendor?.first_name} {vendor?.last_name}</p>
                          </div>

                          <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div>
                              <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                              {lowPrice != null && lowPrice > 0 && (
                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                              )}
                            </div>
                            <div className='book-now border_thmdark brc-dark rounded-5 fs_12 h_30px Rubik-Light d-flex align-items-center px-2-5 text_dark_medium'>View Details</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                )
              })}

              {loadMore && !isLoading && list && list?.length > 0 && (
                <div className='d_flex_align_justify_center  mt-4'>
                  <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                </div>
              )}

              {!isLoading && list && list?.length == 0 && (
                <div className='d_flex_align_justify_center flex-column mt-5'>
                  <img src='/images/not_data.webp' alt='not foud' className='img-fluid h_130px w_200px mt-5' />
                  <div className='Rubik-Regular text_gray2 mt-2'>No packages available</div>
                </div>
              )}
            </Row>
          </Col>
        </Row>

        <Modal
          title="FILTER"
          style={{ top: '8%' }}
          styles={{
            header: {
              padding: '10px'
            },
            body: {
              overflowY: 'auto', maxHeight: '65vh',
              paddingBottom: '10px'
            }
          }}
          footer={null}
          className='Source_Medium text_gray w-xs-auto mx-xs-4 scrollable-width'
          open={open1}
          onOk={handleOk}
          onCancel={() => setOpen1(false)}
        >
          <div className="scrollable">
            <FilterPackages selectedValues={stateVal} locations={list1 || []} onChange={handleCheckboxChange} />
          </div>
        </Modal>

      </div>

      {/* <div className='w-100 mt-5 d-flex align-items-center justify-content-center' style={{ backgroundColor: '#eef4fd' }}>
        <div className='w_95 mt-4'>
          {tagsList && tagsList?.locations?.length > 0 &&
            <div className='bg-white rd_6 p-3  mb-3'>
              <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Locations</h2>

              {tagsList ? (
                <div className='d-flex flex-wrap gap-2'>
                  {tagsList?.locations?.map((it, index) => {
                    return (
                      <div key={it._id} onClick={() => openAbout(it?.name, it._id, 'location')} className='pointer border_thm brc-secondary px-2 rd_6 Source_Regular text-muted fs_13 p-1 bg-light'>
                        {it.name}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <Spin className='ms-2 mb-2 thm-spin' />
              )}
            </div>
          }
          <div className='bg-white rd_6 p-3 mb-3'>
            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States</h2>

            {tagsList ? (
              <div className='d-flex flex-wrap gap-2'>
                {tagsList && tagsList?.states?.map((it, index) => {
                  return (
                    <div key={it._id} onClick={() => openAbout(it?.name, it._id, 'state')} className='pointer border_thm brc-secondary px-2 rd_6 Source_Regular text-muted fs_13 p-1 bg-light'>
                      {it.name}
                    </div>
                  )
                })}
              </div>
            ) : (
              <Spin className='ms-2 mb-2 thm-spin' />
            )}
          </div>

          <div className='bg-white rd_6 p-3 mb-5'>
            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>
            {tagsList ? (
              <div className='d-flex flex-wrap gap-2'>
                {tagsList && tagsList?.countries?.map((it, index) => {
                  return (
                    <div key={it._id} onClick={() => openAbout(it?.name, it._id, 'country')} className='pointer border_thm brc-secondary px-2 rd_6 Source_Regular text-muted fs_13 p-1 bg-light'>
                      {it.name}
                    </div>
                  )
                })}
              </div>
            ) : (
              <Spin className='ms-2 mb-2 thm-spin' />
            )}
          </div>
        </div>
      </div> */}

      <div className='w-100 mt-5 d-flex align-items-center justify-content-center' style={{ backgroundColor: '#eef4fd' }}>
        <div className='w_95 mt-4'>
          {tagsList && tagsList?.locations?.length > 0 &&
            <div className='bg-white rd_6 p-3  mb-3'>
              <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Locations in {stateVal?.cName}</h2>

              {tagsList ? (
                <div className='d-flex flex-wrap gap-2'>
                  {tagsList?.locations?.map((it, index) => {

                    return (
                      <div key={it._id} onClick={() => openAbout(it?.name, it.id, it.item, 'location')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                        Top places to visit in {it.name}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <Spin className='ms-2 mb-2 thm-spin' />
              )}
            </div>
          }

          {tagsList && tagsList?.states?.length > 0 &&
            <div className='bg-white rd_6 p-3 mb-3'>
              <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States in {country == '' ? stateVal?.cName : country}</h2>

              {tagsList ? (
                <div className='d-flex flex-wrap gap-2'>
                  {tagsList && tagsList?.states?.map((it, index) => {
                    return (
                      <div key={it._id} onClick={() => openAbout(it?.name, it.id, it._id, 'state')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                        Top places to visit in {it.name}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <Spin className='ms-2 mb-2 thm-spin' />
              )}
            </div>
          }

          <div className='bg-white rd_6 p-3 mb-5'>
            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>
            {tagsList ? (
              <div className='d-flex flex-wrap gap-2'>
                {tagsList && tagsList?.countries?.map((it, index) => {
                  return (
                    <div key={it._id} onClick={() => openAbout(it?.name, it.id, it._id, 'country')} className='hover-bg-lightblue spointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                      Top places to visit in {it.name}
                    </div>
                  )
                })}
              </div>
            ) : (
              <Spin className='ms-2 mb-2 thm-spin' />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PkgList