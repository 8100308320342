import React, { createContext, useContext, useState } from 'react';

const ChatPopupContext = createContext();

export const ChatPopupProvider = ({ children }) => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    const toggleChatPopup = () => {
        setIsChatOpen(prev => !prev);
    };

    return (
        <ChatPopupContext.Provider value={{ isChatOpen, toggleChatPopup }}>
            {children}
        </ChatPopupContext.Provider>
    );
};

export const useChatPopup = () => {
    return useContext(ChatPopupContext);
};
