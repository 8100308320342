import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const Counter = ({details, setDetails}) => {
    const increment = (field, value, max) => {
        if (value < max) setDetails({...details, [field]:value + 1});
    };

    const decrement = (field, value, min) => {
        if (value > min) setDetails({...details, [field]:value - 1});
    };
    
    // const [rooms, setRooms] = useState(1);
    // const [adults, setAdults] = useState(1);
    // const [children, setChildren] = useState(1);

    return (
        <Row className="counter-container">
            <Col className="counter">
                <span className='Source_Medium fs_14 text-muted'>Rooms <span className='Source_Regular fs_13'>(Max 25)</span></span>
                <div className="counter-controls mb-3 mt-2 d-flex align-items-center">
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => decrement('room', details.room, 1)}>
                        <i className='fa fa-minus fs_13'></i>
                    </div>
                    <span className='fw-regular fs_15 text-muted'>{details.room}</span>
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => increment('room', details.room, 25)}>
                        <i className='fa fa-plus fs_13'></i>
                    </div>
                </div>
            </Col>
            <Col className="counter">
                <span className='Source_Medium fs_14 text-muted'>Adults <span className='Source_Regular fs_13'>(12+ yr)</span></span>
                <div className="counter-controls mb-3 mt-2 d-flex align-items-center">
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => decrement('adults', details.adults, 1)}>
                        <i className='fa fa-minus fs_13'></i>
                    </div>
                    <span className='fw-regular fs_15 text-muted'>{details.adults}</span>
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => increment('adults', details.adults, 50)}>
                        <i className='fa fa-plus fs_13'></i>
                    </div>
                </div>
            </Col>
            <Col className="counter">
                <span className='Source_Medium fs_14 text-muted'>Children <span className='Source_Regular fs_13'>(0-12 yr)</span></span>
                <div className="counter-controls mb-3 mt-2 d-flex align-items-center">
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => decrement('children', details?.children, 0)}>
                        <i className='fa fa-minus fs_13'></i>
                    </div>
                    <span className='fw-regular fs_15 text-muted'>{details?.children}</span>
                    <div className='hw_25px text-center rd_50 bg_thm text-white pointer fs_9 pt-1-5' onClick={() => increment('children', details?.children, 50)}>
                        <i className='fa fa-plus fs_13'></i>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Counter;