import React, { useCallback, useEffect, useState } from 'react'
import { HTMLRenderNormal } from '../../Componets/HTMLRender';
import { dec } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';

const key = process.env.REACT_APP_KEY;

function TC() {
    const [content, setContent] = useState('')

    useEffect(()=>{
        getPrivacy()
    },[])

    const getPrivacy = () => {
            // setIsLoading(true)
            CommonAPI({}, 'GET', `privacyPolicy?type=term_and_condition`, responseBack1)
        };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setContent(dec1)
        }
    })
    return (
        <div className='mt-12 pt-4 container pb-5'>
            <HTMLRenderNormal htmlContent={content}/>            
        </div>
    );

}

export default TC