import toast from "react-hot-toast"

export const tstSuccess =(txt)=>{
    toast.success(txt,{
        position:'top-right'
    })
}
export const tstError =(txt)=>{
    toast.error(txt,{
        position:'top-right'
    })
}