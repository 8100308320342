export const svInfo = (data) => ({
    type: 'SV_INFO',
    payload: data,
});

export const svTripCate = (data) => ({
    type: 'SV_TRIP_CATE',
    payload: data,
});


export const svReceiverDetails = (data) => ({
    type: 'SV_RECEIVER_DETAILS',
    payload: data,
});
export const svMsgId = (data) => ({
    type: 'SV_MSG_ID',
    payload: data,
});