import React from 'react'
import "../CSS/bd-coming-soon.css";

function ComingSoon() {
    return (
        <div className='mt-12 h_400px '>
            {/* <img alt="state" className="img-fluid w-100 h-100" src={`https://img.freepik.com/free-photo/beautiful-girl-standing-viewpoint-koh-nangyuan-island-near-koh-tao-island-surat-thani-thailand_335224-1094.jpg?t=st=1729861709~exp=1729865309~hmac=bccb6a6bade96b9df8ed8c02f0fa7332d2a1cc3d87fd7bb16b0947b179e32755&w=1380`} /> */}
            <img alt="state" className="img-fluid w-100 h-100" src={`https://img.freepik.com/free-photo/rest-sunshine-atoll-bungalow-holiday_1232-3876.jpg?t=st=1729861712~exp=1729865312~hmac=5d411192b0e31c81459aaa5323779d6447e2549c2aae92b190e02ed07caa58a8&w=1380`} />


            <div className='rd_6 d-flex h-100 w-100 justify-content-center align-items-center pointer' style={{
                position: 'absolute',
                top: 0,
                borderRadius: 10,
                background: 'linear-gradient(to top, rgba(0,0,0,0.30), rgba(0,0,0,0.30)) ',
            }}>
                <div className="container text-center ">
                    <h1 className="page-title mt-3 text-white fs_40 text-shadow">Coming soon</h1>
                    <p className="page-description Inter-Regular text-white text-shadow">Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu sit amet consectetur
                    </p>
                    <p className='Inter-Medium text-white text-shadow'>Stay connected with us</p>

                </div>
            </div>
            {/* </main> */}
        </div>
    )
}

export default ComingSoon