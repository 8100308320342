import CryptoJS from 'crypto-js';
import { tstError } from './HotToast';
const key = process.env.REACT_APP_KEY;

export const enc = (dt) => {
    try {
        const jsonString = JSON.stringify(dt)
        return CryptoJS.AES.encrypt(jsonString, key).toString()
    } catch (error) {
        return 'please try again.'
    }
}

export const dec = (dt) => {
    try {
        const byts = CryptoJS.AES.decrypt(dt, key);
        const org = byts.toString(CryptoJS.enc.Utf8);
        const parseOrg = JSON.parse(org);
        return parseOrg
    } catch (error) {
        return 'I appreciate your effort, but please try again.'
    }
}