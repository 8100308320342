import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';

export function HTMLRenderNormal({ htmlContent }) {
  const parsed = parse(htmlContent)
  return parsed
}


const truncateHtml = (html, maxWords) => {
  const text = html.replace(/<\/?[^>]+>/gi, ' '); // Remove HTML tags
  const words = text.split(/\s+/); // Split text into words
  const truncatedText = words.slice(0, maxWords).join(' '); // Get the first `maxWords` words
  return truncatedText;
};

export function HTMLRender({ htmlContent }) {

  const parsed = parse(htmlContent);

  return parsed
}


// useTruncation.js


const useTruncation = (htmlContent) => {
  const contentRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(true);

  useEffect(() => {
    const checkTruncation = () => {
      if (contentRef.current) {
        const isOverflowing =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setIsTruncated(isOverflowing);
      }
    };

    checkTruncation();

    window.addEventListener('resize', checkTruncation); // Check on window resize
    return () => window.removeEventListener('resize', checkTruncation); // Cleanup listener
  }, [htmlContent]);

  return { contentRef, isTruncated };
};

export default useTruncation;
