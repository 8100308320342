import { Modal } from 'antd';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { imagesHt } from '../../Componets/Images';
import { ShareModal } from '../../Componets/Modal';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

function ImgHotel({ img }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const url = window.location.href;

    const remainingImages = img && img.length - 4 || [];

    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };


    return (
        <div className="w_95 d-flex mt-3 align-items-center justify-content-center flex-column">
            <Row className="d-flex w-100">
                <Col md={12} lg={5} className='bg-warning1 position-relative p-0'>
                    <img
                        src={`${img_base_url + img[0]}`}
                        alt={imagesHt[0].alt}
                        onClick={() => {
                            setSelectedImage(img[0])
                            setOpen(true)
                            setOpen2(true)
                        }}
                        className="img-fluid h-lg-380px h-100 w-100 pointer"
                    />
                    <div className="view-all-text pr-0 position-absolute d-lg-none rd_6 pointer mr_10 text-white Source_Medium" onClick={() => {
                        setSelectedImage(img)
                        setOpen(true)
                        setOpen2(false)
                    }}>
                        <i className="bi bi-images mr_10"></i>
                        {imagesHt.length - 1}+
                    </div>
                    <div className='d-lg-none position-absolute pr-0 mt-3 bg_thm pointer h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => setIsModalOpen1(true)}>
                        <p className='Rubik-Regular fs_12 text-white m-0 fa-fade'><i className="fa-solid fa-share-nodes me-1 fs_13"></i> Share</p>
                    </div>
                </Col>
                <Col md={0} lg={7} className='d-none-on-lg'>
                    <Row className=''>
                        {img.slice(0, 4).map((img, i) => (
                            <Col lg={6} className=' h_200px border_gray p-0 position-relative' >
                                <img
                                    src={`${img_base_url + img}`}
                                    alt='hotel img'
                                    onClick={() => {
                                        setSelectedImage(img)
                                        setOpen(true)
                                        setOpen2(true)
                                    }}
                                    className="img-fluid h-100 w-100 pointer"
                                />
                                {i === 1 && remainingImages > 0 && (
                                    <div className='position-absolute pr-0 mt-3 bg_thm pointer h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => setIsModalOpen1(true)}>
                                        <p className='Rubik-Regular fs_12 text-white m-0 fa-fade'><i className="fa-solid fa-share-nodes me-1 fs_13"></i> Share</p>
                                    </div>
                                )}
                                {i === 3 && remainingImages > 0 && (
                                    <div className="view-all-text pr-0 position-absolute rd_6 pointer mr_10 text-white Source_Medium" onClick={() => {
                                        setSelectedImage(img)
                                        setOpen(true)
                                        setOpen2(false)
                                    }}>
                                        <i className="bi bi-images mr_10"></i>
                                        {remainingImages}+
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>

            <Modal
                className='Source_Medium text_gray h_150px overflow-hideen custom-modal'
                style={{ top: '3.5%' }}
                width={'95%'}
                styles={{
                    body: {
                        overflowY: 'auto',
                        width: '100%',
                    },
                }}
                open={open}
                onOk={handleOk}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <div className={`${open2 == true ? 'ant-modal-body' : ''}`}>
                    {open2 == true ? (
                        <img src={`${img_base_url + selectedImage}`} className='img-fluid h-100 rd_4 w-100' />
                    ) : (
                        <Row className='w-100 m-0 p-0 '>
                            {img.map((img, i) => (
                                <Col md={6} lg={6} className='bg-warning1 p-0 h-100 '>
                                    <img
                                        src={`${img_base_url + img}`}
                                        alt='hotel imgs'
                                        onClick={() => {
                                            setSelectedImage(img)
                                            setOpen(true)
                                        }}
                                        className="img-fluid h-100 rd_4 pointer w-100 border_white br-2"
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            </Modal>


            <ShareModal url={url} isVisible={isModalOpen1} onClose={setIsModalOpen1} />
        </div>
    );
}

export default ImgHotel;