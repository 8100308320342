import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { Spin } from 'antd';
import ComingSoon from '../../Componets/ComingSoon';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function Guider() {
  const [open3, setOpen3] = useState(false);
  const [page, setPage] = useState(1)
  const [countryId, setCountryId] = useState('')
  const [hotelType, setHotelType] = useState('')
  const [search, setSearch] = useState('');
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    getList()
  }, [page, hotelType, search == 0 && search]);

  const getList = () => {
    setIsLoading(true)
    CommonAPI({}, 'GET', `guideList?countryId=66a1e7b2e3e1ced4992a2617&location=5 street`, responseBack)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)
      setLoadMore(true);
      if (dec1 == undefined || dec1 == null) {
        setList(dec1)
      } else {
        setList(prevData => {
          const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
          return [...prevData, ...newData];
        });
      }

      if (dec1.length < 9) {
        setLoadMore(false);
      }

    } else {
    }
    setIsLoading1(false)
    setIsLoading(false)
  })

  const handleShowMore = () => {
    setIsLoading1(true)
    setPage(prevPage => prevPage + 1)
  };

  const onChange = (value) => {
    // setOffRerender(false)
    // setList([])
    // setPage(1)
    setCountryId(value)
  };

  return (
    // <div className='mt-7 pt-1 p-0 pb-5' style={{ backgroundColor: '#eef4fd' }}>

    //   {/* Filter */}
    //   <Container fluid className='mb-4 pt-4 pb-4'>
    //     <div className='container'>
    //       <Row className='align-items-end bg-white p-3 rd_6'>
    //         <Col lg={3} xs={12} className='mt-2'>
    //           <label className='Source_Medium text_gray fs_12 mb-1 d-block'>Country</label>
    //           <CountriesDrop onChange={onChange} selected={countryId} />
    //         </Col>
    //         <Col lg={3} xs={12} className='mt-2'>
    //           <label className='Source_Medium text_gray fs_12 mb-1 d-block'>Search location</label>
    //           <input
    //             placeholder='Search location'
    //             value={search}
    //             onChange={(event) => { setSearch(event.target.value) }}
    //             className='custom_field Source_Regular text-secondary w-100 outline-none fs_13 px-2 p-1-5 border_gray rd_6 ' />
    //         </Col>
    //         <Col lg={2} xs={12} className='mt-2'>
    //           <div className='custom_field Source_Medium p-1 px-4 bg-gradient-thm text-white text-center pt-2 rd_6 m-0 auto pointer' onClick={() => { setList([]); setPage(1); getList(); }}>
    //             Search
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   </Container>

    //   {/* Default & By Filter */}
    //   <Container>
    //     <h2 className="Rubik-SemiBold fs_15  mt-4 mb-1 mb-0">Filtered Guiders</h2>
    //     <Row className='bg-light1 pt-3'>
    //       {/* Listing */}
    //       <Col md={7} lg={8}>
    //         {isLoading && (
    //           <div className='d-flex align-items-center justify-content-center mt-4'>
    //             <Spin className='me-3 thm-spin' /> <p className='m-0 Source_Medium text_gray'>Loading...</p>
    //           </div>
    //         )}

    //         <Row className='bg-warning1'>
    //           {list.map((item, index) => {
    //             const add = item.address
    //             const address = add.length > 65 ? add.slice(0, 65) + '...' : add;

    //             const nm = item.firstName + ' ' + item.lastName;
    //             const name = nm.length > 19 ? nm.slice(0, 19) + '...' : nm

    //             return (
    //               <Col xs={9} sm={6} md={6} lg={4} className="m-xxs-auto m-auto mb-4 bg-warning1 px-xl-3">
    //                 <div className='mb-xs-10 bg-white1 p-3 pb-3 text-center h_300px h-xs-300px mt-1 rd_6 bg-white bg-gradient d-flex justify-content-between flex-column'>
    //                   <Link to={'/guiders/details'} state={{ type_id: item._id, type: 'Guider' }} className='text-decoration-none hover-me '>
    //                     <img src={`${img_base_url + item.image}`} className='img-fluid shadow-n p-1 hw_80px rounded-circle position-relative overflow-hidden' />

    //                     <h3 className='Rubik-Medium text_gray fs_15 fs-xs-14 spacing mt-3 m-0'>{name || ''}</h3>
    //                     <p className={`m-0 text-secondary fs_12 Source_Regular bg-warning1`}>{address}</p>
    //                   </Link>
    //                   <div className='mt-3'>
    //                     <div className='d-flex Source_Medium fs_13 text-secondary'>
    //                       <p className='p-0 m-0'>Daily price</p>
    //                       <p className='flex-grow-1 m-0'></p>
    //                       <p className='m-0 text-secondary'>₹{item.chargesDaily}</p>
    //                     </div>
    //                     <div className='d-flex Source_Medium fs_13 text-secondary'>
    //                       <p className='p-0 m-0'>Hourly price</p>
    //                       <p className='flex-grow-1 m-0'></p>
    //                       <p className='m-0 text-secondary'>₹{item.chargesHourly}</p>
    //                     </div>
    //                     <div className='d-flex Source_Medium fs_13 text-secondary'>
    //                       <p className='m-0'>Experience</p>
    //                       <p className='flex-grow-1 m-0'></p>
    //                       <p className='m-0 text-secondary'>{item.experience} Yrs</p>
    //                     </div>
    //                   </div>

    //                   <div className='pointer h_35px border_thm p-1 mt-2-5 rd_50 fs_12 px-2 h-xxs-35px text_thm Source_Medium d-flex align-items-center justify-content-center'>
    //                     Book Now
    //                   </div>
    //                 </div>
    //               </Col>
    //             )
    //           })}

    //           {loadMore && !isLoading && list && list?.length > 0 && (
    //             <div className='d_flex_align_justify_center  mt-4'>
    //               <button className='btn bg-gradient-thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
    //             </div>
    //           )}

    //           {!isLoading && list && list?.length == 0 && (
    //             <div className='d_flex_align_justify_center flex-column mt-5'>
    //               <img src='/icons/not-found.png' alt='not foud' className='img-fluid h_100px w_100px mt-5' />
    //               <h5 className='Source_SemiBold mt-4'>no data found</h5>
    //             </div>
    //           )}
    //         </Row>
    //       </Col>

    //       <Col md={5} lg={4} className=''>
    //         <div id='query' className='mt-1 d-flex bg-gradient-thm h_150px'>
    //           <div className='bg-warning1 flex-grow-1 ms-2 d-flex flex-column justify-content-center align-items-center'>
    //             <h3 className='Source_Bold text-white fs_20 m-0 mt-1 mb-1'>Looking for</h3>
    //             <p className='Source_Regular text-white m-0 fs_14'>Package, Hotel, Vehicle, Destination, Tour...</p>
    //             <Link to={'/post-travel'} className='btn bg-white mt-4 Rubik-Regular fs_13 text_thm' style={{color:'#2ac64c'}}>
    //             Post Your Travel
    //             </Link>
    //           </div>
    //         </div>


    //         {/* ===========================Tags section================================ */}
    //         <div className='bg-white rd_6 p-3 mt-5'>
    //           <h2 className="Rubik-SemiBold fs_15 mt-1 mb-3">Tags :</h2>

    //           <div className='d-flex flex-wrap gap-2'>
    //             {lehLadakhTags.map((it) => (
    //               <div className='pointer px-2 rd_6 Source_Regular text-muted fs_13 p-1 bg-light'>
    //                 {it}
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>

    //   {/* Query Spons */}

    //   {/* From */}
    //   <FormVehicleModal isVisible={open3} onClose={setOpen3} />
    // </div >
    <ComingSoon/>
  )
}

export default Guider