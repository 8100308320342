import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import {HTMLRenderNormal} from '../../Componets/HTMLRender'
import { PackagesImgs, Summary } from '../../Componets/Images'
import { OpenImageModal } from '../../Componets/Modal';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { useLocation } from 'react-router-dom';
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const key = process.env.REACT_APP_KEY;

function VehicleDetails() {
    const location = useLocation();
    const state = location?.state
    const [open2, setOpen2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [err, setErr] = useState({})
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [num, setNum] = useState('');
    const [description, setDescription] = useState('');
    const [location1, setLocation] = useState('');
    const [switchTab, setSwitchTab] = useState('Inclusions');
    const [data, setData] = useState([]);

    const html = `<p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac nisi nec elit congue cursus. Duis et ligula at libero dignissim auctor a ac arcu. Suspendisse potenti. Sed sed risus ut nulla fermentum vehicula. Integer at libero in justo sagittis tincidunt eget sit amet sapien. Aenean pharetra facilisis nulla, eu volutpat leo tristique ac. Nam auctor quam et libero viverra, ac volutpat nulla posuere.
</p>
`
    useEffect(() => {
        getDetails();
    }, []);



    const getDetails = () => {
        // const enc1 = enc(data, key);
        setIsLoading1(true)
        CommonAPI({}, 'GET', `vehicleDetails?id=${state?.type_id}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);
            setData(dec1)
        } 
        setIsLoading1(false)
    })

    const inclusions = [
        'Air Conditioning',
        'Leather Seats',
        'Navigation System',
        'Bluetooth Connectivity',
        'Backup Camera'
    ];

    const exclusions = [
        'Sunroof',
        'Heated Seats',
        'Adaptive Cruise Control',
        'Premium Audio System',
        'Automatic Parking Assistance'
    ];

    return (
        <div
            className='mt-7 pb-5'
            style={{
                backgroundColor: '#eef4fd',
                backgroundImage: `url('/shape/shape_top_bg_orange.png')`,
                backgroundSize: 'contain',
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                paddingTop: '50px',
            }}
        >
            <Container className='bg-success1'>
                <Row>
                    <Col lg={8} className=''>
                    {data && !isLoading1 && data?.map((item) => {
                            return (
                                <>
                                    <div className='bg-white shadow-n rd_6 p-4 d-flex flex-row align-items-start' style={{ zIndex: 2, position: 'relative' }}>
                                        <div className='bg-white shadow-n1 d-flex align-items-center p-3 rd_6 h_100px'>
                                            <img src='/images/wagonr.png' alt='' className='img-fluid w_80px' />
                                        </div>
                                        <div className='ms-3'>
                                            <text className='Rubik-SemiBold fs_22'>{item.name}</text>
                                            <div className='d-flex Rubik-Regular text-secondary fs_14 mb-2'>
                                                <text className='text-capitalize'>{item.airCondition}</text>
                                                <div className='ms-2'>
                                                    <span>{item.sheets} Seats</span>
                                                </div>
                                            </div>

                                            <div className='Rubik-Regular fs_13 mb-1'><i className='bi bi-check2-circle me-2'></i>65 kms included. After that ₹{item.charges}.0/km</div>
                                            <div className='Rubik-Regular fs_13 mb-1'><i className='bi bi-check2-circle me-2'></i>Free cancellation until 1 hour before pickup</div>
                                            <div className='Rubik-Regular fs_13 mb-1 text-capitalize'><i className='bi bi-fuel-pump-fill me-2'></i>{item.vehicleType} Car</div>
                                        </div>
                                    </div>
                                    <div className='bg-white rd_6 p-4' style={{ top: -15, position: 'relative' }}>
                                        <div className='Rubik-SemiBold fs_18 mt-3'>Details</div>
                                        <p className='m-0 Source_Regular text_gray fs_13 mt-2'>
                                            <HTMLRenderNormal htmlContent={item && item?.details || ''} />
                                        </p>

                                        <div className='Rubik-SemiBold fs_18'>Gallery</div>
                                        {/* <div className='bg-warning1 d-flex flex-wrap gap-3 mt-2'>
                                            {item && item?.gallery.map((img, i) => (
                                                <img src={`${img_base_url + img}`} className='hw_80-85px rd_6 pointer'
                                                    onClick={() => {
                                                        setSelectedImage(img)
                                                        setOpen2(true)
                                                    }} />
                                            ))}
                                        </div> */}
                                    </div>

                                    {/* Tabs Summary */}
                                    {/* <div className='bg-warning1 d-flex mt-4 ms-2 scrollable overflow-x-auto white-space'>
                                        {Summary.map((it, index) => {
                                            if (it !== 'Gallery') {
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => setSwitchTab(it)}
                                                        style={{ backgroundColor: '#f1f1f1' }}
                                                        className={`mt-2 pointer p-1 mx-1 px-3 d-flex align-items-center h_40px rd_tl_tr_6 ${it == switchTab ? 'Rubik-Medium fs_14 current-month text_thm bg-white' : 'Sansation_Regular text_gray2 fs_14'}`}
                                                    >
                                                        {it}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className='bg-white rd_6 p-4'>
                                        {switchTab == 'Inclusions' ? (
                                            <>
                                                {item.inclusions.map((name, i) => (
                                                    <p className='Source_Regular fs_14 m-0 mt-1 mb-1 text-capitalize'><i className='bi bi-check2-circle text_thmdark mr_10'></i>{name}</p>
                                                ))}

                                            </>
                                        ) : (
                                            <>
                                                {item.exclusions.map((name, i) => (
                                                    <p className='Source_Regular fs_14 m-0 mt-1 mb-1 text-capitalize'><i className='bi bi-x text-danger mr_10'></i>{name}</p>
                                                ))}
                                            </>
                                        )}
                                    </div> */}
                                </>
                            )
                        })}
                    </Col>

                    <Col>
                        <div className='bg-white rd_6 p-3' style={{}}>
                            <div className='d-flex'>
                                <div className='flex-grow-1'>
                                    <div className='Rubik-SemiBold fs_18'>Total Amount</div>
                                    <div className='Rubik-Regular fs_12 text_gray2'>Inclusive of GST</div>
                                </div>
                                <div>
                                    <text className='Rubik-SemiBold fs_18'>₹2213</text>
                                    { }
                                </div>
                            </div>
                            <div className='divider bg_graylight mt-3 h_0-5px'></div>
                            {/* <div className="form-group flex-grow-1 mt-3">
                                <label className='Source_Medium fs_13 mb-1'>Full Name</label>
                                <input type="text" className="form-control mb-3 fs_13 h_35px" required placeholder="Enter Full Name" value={value} onChange={(event) => setValue(event.target.value)} />
                                {err.name && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.name}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                <label className='Source_Medium fs_13 mb-1'>Email</label>
                                <input type="email" className="form-control mb-3 fs_13 h_35px" required placeholder="Enter Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                {err.mail && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.mail}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                <label className='Source_Medium fs_13 mb-1'>Phone Number</label>
                                <input type="number" className={`form-control fs_13 h_35px ${err.num ? 'mb-1' : 'mb-3'}`} required placeholder="Phone number" value={num} onChange={(event) => setNum(event.target.value)} />
                                {err.num && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.num}</p>}
                            </div>

                            <div className="form-group flex-grow-1 mt-3">
                                <label className='Source_Medium fs_13 mb-1'>Enter Pickup Location</label>
                                <input type="text" className="form-control mb-3 fs_13 h_35px" required placeholder="Enter pickup location" value={location} onChange={(event) => setLocation(event.target.value)} />
                                {err.location && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.location}</p>}
                            </div>

                            <div className="form-group mb-3">
                                <label className='Source_Medium fs_14 text-muted mb-1'>Enter Message</label>
                                <textarea name="description" placeholder='meesage' rows={4} className="form-control Source_Regular fs_14 text_gray" required value={description} onChange={(event) => setDescription(event.target.value)} />
                                {err.msg && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.msg}</p>}
                            </div> */}

                            <button className='bg_thm btn w-100 mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Rubik-Medium p-1-5 px-2' disabled={isLoading}>
                                {isLoading ? <Spinner size='sm' /> : 'Pay ₹2213 & Confirm Booking'}
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <OpenImageModal type='vehicle' isVisible={open2} onClose={setOpen2} img={selectedImage} />
        </div>

    )
}

export default VehicleDetails