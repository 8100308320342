import React, { useCallback, useContext, useEffect, useState } from 'react'
import { dec } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dateFormat } from '../../Componets/CommonFs';
import parse from 'html-react-parser';
import { MemContext } from '../../ContextRedux/Context';
import { Tooltip } from '@mui/material';
import ComingSoon from '../../Componets/ComingSoon';

const responsive_7 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 9
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 8,
        slidesToSlide: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 6,
        slidesToSlide: 6
    },
    mobile: {
        breakpoint: { max: 767, min: 666 },
        items: 5,
        slidesToSlide: 5
    },
    mobilex: {
        breakpoint: { max: 666, min: 0 },
        items: 4,
        slidesToSlide: 4
    }
};

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;


function Vehicleindex() {
    const { userInfo } = useContext(MemContext);

    const [passengers, setPassengers] = useState(1);
    const [date, setDate] = useState([])
    const [list, setList] = useState({});
    const [list1, setList1] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState({ open: false, terms: '' })

    //listing
    const [page, setPage] = useState(1)
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')

    const [countryId, setCountryId] = useState('')
    const [typeId, setTypeId] = useState('')
    const [totalItems, setTotalItems] = useState(0)
    const [reload, setReload] = useState(0);

    const [offRerender, setOffRerender] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [error, setError] = useState({ to: false, from: false })
    const [list2, setList2] = useState([]);


    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = dateFormat(date, 'dmy').replace(/\//g, '-')
            setDate(formattedDate);
        } else {
            setDate([]);
        }
    };

    useEffect(() => {
        getVehicles();
    }, []);

    const getVehicles = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `vehicleHome`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setList(dec1)
        } else {
        }
        setIsLoading(false)
    })

    useEffect(() => {
        getList(countryId);
    }, [page, reload, typeId]);


    const getList = (countryId) => {
        const userCountry = userInfo !== null && userInfo?.countryId?._id

        setIsLoading1(true)
        CommonAPI({}, 'GET', `vehicleList?countryId=${countryId || userCountry}&from=${from}&to=${to}&type=${typeId}&page=${page}`, responseBack1)
    }

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt?.data, key)
            setLoadMore(true);
            setList2([])
            if (dec1 == undefined || dec1 == null) {
                setList2(dec1)
            } else {
                setList2(prevData => {
                    const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                    return [...prevData, ...newData];
                });
            }
            setTotalItems(dt.pagination.totalItems);

            if (dec1.length < 10) {
                setLoadMore(false);
            }

        } else {
        }
        setIsLoading1(false)
        setIsLoading2(false)
    })


    const handleShowMore = () => {
        setIsLoading1(true)
        setPage(prevPage => prevPage + 1)
    };

    const vehicles = list?.vehicletype || [];

    const onChange = (value, type) => {
        setOffRerender(false)
        const rn = Math.floor(Math.random() * 9999 + 1)
        setReload(rn)
        setList2([])
        setCountryId(value)
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen2(false);
        }, 1000);
    };

    const handleSelect = (id) => {
        setOffRerender(false);
        setList2([]);

        if (typeId == id) {
            setTypeId('')
        } else {
            setTypeId(id)
        }
    }

    const val = () => {
        const err = {}

        if (!from && from.trim().length < 2) {
            err.from = true
        }
        if (!to && to.trim().length < 2) {
            err.to = true
        }

        setError(err)
        return Object.keys(err).length === 0
    }

    const handleSearch = () => {
        // setIsLoading2(true)
        if (val()) {
            setOffRerender(false);
            setList2([]);
            getList();
            setOffRerender(false);
        }
    }
    const userCountry = userInfo !== null && userInfo?.countryId?._id

    return (
        // <div className='mt-7 pt-1 p-0 pb-5 bg-white'>

        //     <Container fluid className='mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center' style={{ backgroundColor: '#eef4fd' }}>
        //         {/* Filter */}
        //         <div className='container p-3 bg-white rd_6'>
        //             <Row className='bg1-warning align-items-end bg-warning1 h-100 d-none-on-lg p-0 position-relative' >
        //                 <Col lg={3} xs={2} className='bg-success1 h-100'>
        //                     <label className='Source_Medium text_gray fs_12 mb-1 d-block'>Country</label>
        //                     <CountriesDrop onChange={onChange} selected={countryId || userCountry} />
        //                 </Col>
        //                 <Col lg={3} xs={2} className='bg-secondary1 h-100 m-0'>
        //                     <label className='Source_Medium text_gray fs_12 mb-1 d-block'>From</label>
        //                     <Tooltip
        //                         title="Please enter your location"
        //                         arrow
        //                         open={error.from}
        //                         PopperProps={{
        //                             modifiers: [
        //                                 {
        //                                     name: 'offset',
        //                                     options: {
        //                                         offset: [0, -10],
        //                                     },
        //                                 },
        //                             ],
        //                         }}
        //                     >
        //                         <input
        //                             placeholder='pickup location'
        //                             value={from}
        //                             onChange={(event) => { setOffRerender(false); setFrom(event.target.value) }}
        //                             className='Source_Regular custom_field text-secondary w-100 outline-none fs_13 px-2 p-1-5 border_gray rd_6 '
        //                         />
        //                     </Tooltip>
        //                     {/* {from.length < 2 && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>Please Enter Valid Location</p>} */}
        //                 </Col>
        //                 <Col lg={3} xs={2} className='bg-danger1 position-relative'>
        //                     <label className='Source_Medium text_gray fs_12 mb-1 d-block'>To</label>
        //                     <Tooltip
        //                         title="Please enter valid destination"
        //                         arrow
        //                         open={error.to}
        //                         PopperProps={{
        //                             modifiers: [
        //                                 {
        //                                     name: 'offset',
        //                                     options: {
        //                                         offset: [0, -10],
        //                                     },
        //                                 },
        //                             ],
        //                         }}
        //                     >
        //                         <input
        //                             placeholder='Drop location'
        //                             value={to}
        //                             onChange={(event) => setTo(event.target.value)}
        //                             className={`Source_Regular custom_field text-secondary w-100 outline-none fs_13 px-2 p-1-5 border_gray rd_6 ${error ? 'border-danger' : ''}`}
        //                         />
        //                     </Tooltip>

        //                     {/* <div className="tooltip text-danger">Please fill this field</div> */}
        //                     {/* {to.length < 2 && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>Please Enter Valid Location</p>} */}
        //                 </Col>
        //                 {/* <Col lg={2} xs={2} className='bg-success'>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Travel Date</label>
        //                     <DatePicker
        //                         className='fs_12 text_gray custom_field w-100'
        //                         onChange={handleDateChange}
        //                         format={'DD-MM-YYYY'}
        //                     // value={date}
        //                     />
        //                 </Col> */}
        //                 <Col lg={2} xs={3} className='h-100'>
        //                     <div className='d-flex align-items-end h-100'>
        //                         <button className='btn Source_Medium p-1 custom_field d-flex align-items-center justify-content-center px-2 bg-gradient-thm text-white rd_6 m-0 w_100px pointer' disabled={isLoading1} onClick={() => handleSearch()}>
        //                             Search
        //                         </button>
        //                     </div>
        //                 </Col>
        //                 {/* <div>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Passengers</label>
        //                     <div className='Source_Regular fs_13 bg-white px-4 text-secondary p-1-5 border_gray rd_6 pointer' onClick={() => setOpen1(true)}>{passengers} Passengers</div>
        //                 </div> */}
        //             </Row>
        //             <p className='Source_Medium text_thm p-1 px-2 rd_6 fs_14 bg_thmlight d-inline-block pointer m-0 d-lg-none mr_10 ' onClick={() => setOpen2(true)}><i className='bi bi-search fs_13 mr_5'></i> Search</p>
        //         </div>
        //     </Container>

        //     <Container className="bg-warning1 mt-3">

        //         <h2 className="Source_Bold fs_15 mb-0 mt-5">Select Vehicle</h2>
        //         <Carousel
        //             swipeable={true}
        //             draggable={true}
        //             responsive={responsive_7}
        //             ssr={true}
        //             autoPlaySpeed={2500}
        //             keyBoardControl={true}
        //             customTransition="transform 1000ms ease-in-out"
        //             containerClass="carousel-container mt-3"
        //             itemClass="carousel-item1 "
        //             customLeftArrow={
        //                 <CustomLeftArrow
        //                     direction="left"
        //                 />
        //             }
        //             customRightArrow={
        //                 <CustomLeftArrow
        //                     direction="right"
        //                 />
        //             }
        //             dotListClass="custom-dot-list-style"
        //         >
        //             {vehicles.map((item) => {
        //                 return (
        //                     <div key={item.name} onClick={() => { handleSelect(item._id) }} className={`bg-success1 mr_10 mt-1 d-flex flex-column align-items-center justify-content-center pointer`}>
        //                         <div className={`w-100 rd_10 hw_80px d-flex align-items-center shadow-n1 justify-content-center bg-white  `}>
        //                             <img src={`${img_base_url + item.image}`} className='hw_50px rd_6' />
        //                         </div>
        //                         <p className={`m-0 mt-2 fs_14 Source_Medium text-muted text-center ${typeId == item._id ? 'text_thm' : ''}`}>{item.name}</p>
        //                     </div>
        //                 )
        //             })}
        //         </Carousel>

        //         <h2 className="Source_Bold fs_15 mt-5 mb-1">Vehicles found ({totalItems})</h2>
        //         {isLoading1 && (
        //             <div className='d-flex align-items-center justify-content-center mt-4'>
        //                 <Spin className='me-3 thm-spin' /> <p className='m-0 Source_Medium text_gray'>Loading...</p>
        //             </div>
        //         )}

        //         <Row className='bg-primary1 mt-4'>
        //             {/* Listing */}
        //             {list2.map((item) => {
        //                 const terms = parse(item.details);

        //                 return (
        //                     <Col key={item._id} xs={6} sm={6} md={4} lg={3} className="m-xxs-auto mb-4 w-xxs-90 bg-warning1">
        //                         <div className='mb-xs-10 shadow-n1 bg-white rd_6 p-0 overflow-hidden position-relative'>
        //                             <Link to={'/vehicles/details'} state={{ type_id: item._id, type: 'Vehicle' }} className='text-decoration-none hover-me'>
        //                                 <div className='bg-white p-2'>
        //                                     <img src={`${img_base_url + item.image}`} className='img-fluid h_120px w-100 position-relative bg-white overflow-hidden' />
        //                                 </div>
        //                                 <div className='p-1 px-2' style={{
        //                                     position: 'absolute',
        //                                     top: 0,
        //                                     width: 'auto',
        //                                     height: 'auto',
        //                                     borderBottomRightRadius: 10,
        //                                     background: 'rgba(0,0,0,0.6)'
        //                                 }}>
        //                                     <p className='Source_Regular text-white fs_12 m-0'>Starting ₹8932</p>
        //                                 </div>
        //                                 <h3 className='Rubik-Medium text_gray fs_15 fs-xs-14 spacing ps-2 pt-2'>{item.name}</h3>

        //                                 <div className="p-2 px-2-5 overflow-hidden position-relative">
        //                                     <div className='d-flex align-items-center Source_Medium'>
        //                                         <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Seat Included</p>
        //                                         <p className='fs_14 bg-warning1 m-0 text_gray2'>{item.sheets}</p>
        //                                     </div>
        //                                     <div className='d-flex align-items-center Source_Medium'>
        //                                         <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Luggage Bag</p>
        //                                         <p className='fs_14 bg-warning1 m-0 text_gray2'>{item.luggage || 0}</p>
        //                                     </div>
        //                                     <div className='d-flex align-items-center Source_Medium'>
        //                                         <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Driver Charges</p>
        //                                         <p className='fs_14 bg-warning1 m-0 text_gray2'>Included</p>
        //                                     </div>
        //                                     <div className='d-flex align-items-center Source_Medium'>
        //                                         <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Per KM</p>
        //                                         <p className='fs_14 bg-warning1 m-0 text_gray2'> ₹{item.charges}</p>
        //                                     </div>

        //                                     <label className='Source_Regular text-info fs_12 mb-1 pointer' onClick={() => { setOpen4({ open: true, terms: terms }) }}>Other Terms & Charges</label>

        //                                     <div className='pointer p-1 mt-2 rd_50 h-xxs-35px border_thm text_thm Source_Medium d-flex align-items-center justify-content-center' onClick={() => setOpen3(true)}>
        //                                         Book Now
        //                                     </div>
        //                                 </div>
        //                             </Link>
        //                         </div>
        //                     </Col>
        //                 )
        //             })}

        //             {loadMore && !isLoading && list2 && list2?.length > 0 && (
        //                 <div className='d_flex_align_justify_center  mt-4'>
        //                     <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
        //                 </div>
        //             )}

        //             {!isLoading && list2 && list2?.length == 0 && (
        //                 <div className='d_flex_align_justify_center flex-column mt-5'>
        //                     <img src='/icons/not-found.png' alt='not foud' className='img-fluid h_100px w_100px mt-5' />
        //                     <h5 className='Source_SemiBold mt-4'>no data found</h5>
        //                 </div>
        //             )}
        //         </Row>

        //     </Container>

        //     {/* Query Spons */}

        //     <Modal
        //         title="Search Me"
        //         className='Source_Medium text_gray w_60 w-xs-auto mx-xs-4'
        //         bodyStyle={{ padding: '15px', height: 'auto' }}
        //         styles={{
        //             header: {
        //                 padding: '10px 10px 0 10px',
        //             },

        //         }}
        //         open={open2}
        //         onOk={handleOk}
        //         footer={null}
        //         onCancel={() => setOpen2(false)}
        //     >
        //         <div className='bg-white rd_6'>
        //             <Row className='bg1-warning align-items-end ' >
        //                 <Col xs={12} sm={6} md={4} lg={3} className='mt-2 bg1-success'>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Country</label>
        //                     {/* <CountriesDrop onChange={onChange} selected={countryId} /> */}
        //                 </Col>
        //                 <Col xs={12} sm={6} md={4} lg={3} className='mt-2 bg1-warning'>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>From</label>
        //                     <input
        //                         placeholder='pickup location'
        //                         value={from}
        //                         onChange={(event) => { setOffRerender(false); setFrom(event.target.value) }}
        //                         className='Source_Regular custom_field text-secondary w-100 outline-none fs_13 px-2 p-1-5 border_gray rd_12 '
        //                     />
        //                 </Col>
        //                 <Col xs={12} sm={12} md={4} lg={3} className='mt-2 bg1-danger'>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>To</label>
        //                     <input
        //                         placeholder='drop location'
        //                         value={to}
        //                         onChange={(event) => { setOffRerender(false); setTo(event.target.value) }}
        //                         className='Source_Regular custom_field text-secondary w-100 outline-none fs_13 px-2 p-1-5 border_gray rd_6 ' />
        //                 </Col>
        //                 {/* <Col lg={2} xs={2} className='bg-success'>
        //                     <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Travel Date</label>
        //                     <DatePicker
        //                         className='fs_12 text_gray custom_field w-100'
        //                         onChange={handleDateChange}
        //                         format={'DD-MM-YYYY'}
        //                     // value={date}
        //                     />
        //                 </Col> */}
        //                 {/* <Col lg={2} xs={3}> */}
        //                 {/* </Col> */}
        //             </Row>
        //             <div className='d-flex justify-content-end mt-4'>
        //                 <button className='btn Source_Medium p-1 custom_field d-flex align-items-center justify-content-center px-2 bg_thm text-white rd_6 m-0 w_100px pointer' disabled={isLoading1} onClick={() => { setOffRerender(false); setList2([]); getList(); setOffRerender(false); }}>
        //                     Search
        //                 </button>
        //             </div>
        //         </div>
        //     </Modal>

        //     <Container>
        //         {/* <h2 className="Source_Bold fs_15 mt-1 mb-2 mt-5">Shimla Packages</h2>
        //         <RecoPkg /> */}

        //         {/* <h2 className="Source_Bold fs_15 mt-5 mb-3">Hotels in Shimla</h2>
        //         <RecoHotel /> */}

        //         {/* Passengers Modal */}
        //         <PassengersModal isVisible={open1} onClose={setOpen1} pase={passengers} setPase={setPassengers} />

        //         {/* Other Charges */}
        //         <ParagraphModal isVisible={open4} onClose={setOpen4} />

        //         {/* Search Destination Cab */}
        //         {/* <SearchCabLoc isVisible={open2} onClose={setOpen2} /> */}

        //         {/* From */}
        //         <FormVehicleModal isVisible={open3} onClose={setOpen3} />
        //     </Container>
        // </div >
        <ComingSoon/>
    )
}

export default Vehicleindex