import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './CSS/common.css'
import './CSS/Fonts.css'
import './CSS/Footer.css'
import './CSS/Index.css'

// Components
import { RouterParent } from './Routes/RouterParent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Context from './ContextRedux/Context';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { store } from './ContextRedux/Store';
import { MsgNoti } from './Componets/Modal';
import { NotificationProvider } from './ContextRedux/NotificationContext';
import { LoadScript } from '@react-google-maps/api';
import { ChatPopupProvider } from './ContextRedux/ChatPopupContext';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const g_id = process.env.REACT_APP_G_ID;
const g_map_key = process.env.REACT_APP_G_MAP_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(function (registration) {
    })
    .catch(function (error) {
    });
}
const libraries = ['places'];


root.render(
  <NotificationProvider>
    <ChatPopupProvider>
      <LoadScript googleMapsApiKey={g_map_key} libraries={libraries} loadingElement={<div style={{ display: 'none' }}></div>}>
        <Provider store={store}>
          <Context>
            <GoogleOAuthProvider clientId={g_id}>
              <React.StrictMode>
                <RouterProvider router={RouterParent} />
                <Toaster />

              </React.StrictMode>
            </GoogleOAuthProvider>
          </Context>
        </Provider>
      </LoadScript>
    </ChatPopupProvider>
  </NotificationProvider>
);

// {/* <UserDetail /> */}
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
