import { DatePicker, Modal } from 'antd'
import React, { useState } from 'react'

function SearchCabLoc({ isVisible, onClose }) {
    const [open1, setOpen1] = useState(false);
    const [passengers, setPassengers] = useState(1);
    const [dates, setDates] = useState([])

    const handleDateChange = (date) => {
        if (date) {
            setDates(date);
        } else {
            setDates(null);
        }
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            onClose(false);
        }, 1000);
    };

    return (
        <>
            <Modal
                title="Search"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body:{
                        padding: '10px 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0 10px 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={isVisible}
                onOk={handleOk}
                onCancel={() => onClose(false)}
                footer={null}
            >
                <div className=' d-flex flex-column gap-2'>
                    <div className='d-flex Source_Medium fs_13 gap-2 flex-column-xs'>
                        <input placeholder='From' className='outline-none flex-grow-1 px-2 p-1-5 border_gray rd_6 ' />
                        <input placeholder='To' className='outline-none flex-grow-1 px-2 p-1-5 border_gray rd_6 ' />
                    </div>
                    <div className='d-flex mb-xs-10'>
                        <DatePicker
                            className='fs_12 text_gray flex-grow-1 mr_10'
                            onChange={handleDateChange}
                            value={dates}
                        />
                        <div className='Source_Medium fs_13 bg-white flex-grow-1 px-4 text-secondary p-1-5 border_gray rd_6 pointer' onClick={() => setOpen1(true)}>{passengers} Passengers</div>
                    </div>
                    <div className='d-flex justify-content-end mt'>
                        <div className='Source_Medium p-1 px-2 bg_thm text-white h_40px d-flex align-items-center justify-content-center rd_6 m-0 w_150px'>
                            Search
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Search Destination Cab */}
            <PassengersModal isVisible={open1} onClose={setOpen1} pase={passengers} setPase={setPassengers}/>
        </>
    )
}

export default SearchCabLoc

const PassengersModal = ({ isVisible, onClose, pase, setPase }) => {

    const increment = (setter, value, max) => {
        if (value < max) setter(value + 1);
    };

    const decrement = (setter, value, min) => {
        if (value > min) setter(value - 1);
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            onClose(false);
        }, 1000);
    };
    return (
        <Modal
            title="Passengers"
            className='Source_Medium text_gray w-xs-auto mx-xs-4'
            styles={{
                body:{
                     padding: '0 20px', height: 'auto'
                },
                header: {
                    padding: '15px 0px 0 15px',
                },
                footer: {
                    padding: '10px'
                }
            }}
            open={isVisible}
            onOk={handleOk}
            onCancel={() => onClose(false)}
            footer={null}
        >
            <div>
                <div className="counter-controls mb-3 mt-2 d-flex align-items-center">
                    <div className={`hw_25px text-center rd_50 text-white pointer fs_9 pt-1-5 ${pase == 1 ? 'bg_gray2' : 'bg_thm'}`} onClick={() => decrement(setPase, pase, 1)}>
                        <i className='fa fa-minus fs_13'></i>
                    </div>
                    <span className='fw-regular fs_15 text-muted'>{pase}</span>
                    <div className={`hw_25px text-center rd_50 text-white pointer fs_9 pt-1-5 ${pase == 8 ? 'bg_gray2' : 'bg_thm'}`} onClick={() => increment(setPase, pase, 8)}>
                        <i className='fa fa-plus fs_13'></i>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export {PassengersModal}