import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Container, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dateFormat } from '../../Componets/CommonFs';
import { tstSuccess } from '../../Componets/HotToast';
import { ActionModal } from '../../Componets/Modal';
import { MemContext } from '../../ContextRedux/Context';
import { useSelector } from 'react-redux';
import { dec } from '../../Componets/SecRes';

const categories = ["Packages", "Tour", "Destination"];
const key = process.env.REACT_APP_KEY;

function MyQueries(type) {
    const { userInfo } = useContext(MemContext);
    const [expanded, setExpanded] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [open1, setOpen1] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [country, setCountry] = useState('')
    const [selectedValues, setSelectedValues] = useState({ "min": '', "max": '' });
    const [selectedValues1, setSelectedValues1] = useState([]);


    const maxWord = 120
    const user = useSelector(state => state.data.info.user)


    useEffect(() => {
        getList(page);
    }, [page, selectedValues, selectedValues1, search, country]);

    const getList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `queryList?userId=${user?._id}&query=${search}&page=${page}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            // setTotalItms(dt?.pagination.totalItems)
            const dec1 = dec(dt?.data, key)
            setLoadMore(true);
            if (dec1?.result == undefined || dec1?.result == null) {
                setList(dec1?.result)
            } else {
                setList(prevData => {
                    const newData = dec1?.result?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                    return [...prevData, ...newData];
                });
            }

            if (dec1?.result?.length < 10) {
                setLoadMore(false);
            }

        }
        setIsLoading1(false)
        setIsLoading(false)
    })

    const handleClick = (event, i) => {
        event.preventDefault();
        setExpanded(prev => ({
            ...prev,
            [i]: !prev[i]
        }));
    };

    const handleShowMore = () => {
        setIsLoading1(true)
        setPage(prevPage => prevPage + 1)
    };

    const handleSearch = (e) => {
        setList([]);
        setPage(1)
        getList(search)
    };

    const handleReload =()=>{
        setList([])
        getList()
    }

    return (
        <div className={`bg-warning1 p-0 bg-light ${type.type == 'FT' ? 'mt-4-7' : 'mt-12 pt-3'}`} style={{ backgroundColor: '#eef4fd' }}>
            <Container className='bg-warning1 mt-4 pb-4'>

                <Row className='bg-white rd_6 p-3 gap-3'>
                    <Col sm={8} md={8} lg={10} className='bg-primary11 p-0 flex-grow-1'>
                        <InputGroup className=''>
                            <InputGroup.Text className='input-group-text h_40px'>
                                <i className='fa fa-search text_thm'></i>
                            </InputGroup.Text>
                            <FormControl
                                className='search-input text_gray fs_13 rd_tr_br_6 px-2 h_40px'
                                type='text'
                                placeholder='Search'
                                style={{ height: 35, color: 'red' }}
                                value={search}
                                onChange={(event) => { setSearch(event.target.value); }}
                            />
                        </InputGroup>
                    </Col>
                    <Col  className='bg-warning1 d-flex align-items-end p-0'>
                        <div className='Source_Medium bg_thm h_40px w-100 text-white d-flex align-items-center justify-content-center rd_6 m-0 pointer' onClick={handleSearch}>
                            Search
                        </div>
                    </Col>
                </Row>

                <Row className='bg-warning1 gap-3 mt-4 p-0'>

                    {list && list?.map((itm, i) => {
                        const user = itm.userDetails && itm?.userDetails
                        const dt = dateFormat(itm.createdAt)
                        const word = itm && itm.description && itm.description.split(/\s+/);

                        const isExpanded = expanded[i] || false;
                        const st = itm?.deletedAt == 1;

                        return (
                            <Col lg={12} className='p-0'>
                                <div className={`rd_6 shadow-none p-3 `} style={{ backgroundColor: st ? '#fff' : 'white' }}>
                                    <Link to={st ? "" : "/post-travel/details"}
                                        state={itm._id}
                                        className={`text-decoration-none position-relative ${st ? 'cursor-auto-1' : ''}`}>

                                        <div className='d-flex position-relative'>
                                            <div className='d-flex flex-wrap flex-grow-1'>
                                                <p className='Rubik-Medium text_thmdark flex-grow-1 m-0 text-capitalize'>{user?.first_name + ' ' + user?.last_name} <span className='Source_Regular fw-normal fs_14'>({itm.queryCountry && itm?.queryCountry[0]?.name})</span></p>
                                                <p className='Source_Regular m-0 text_gray fs_12'>{dt}</p>
                                            </div>
                                            {!st && (
                                                <ActionModal isVisible={isShow} onClose={setIsShow} data={itm} reload={handleReload}/>
                                            )}
                                        </div>

                                        <p className=" Source_Regular fs_14 text_gray mt-3 position-relative">
                                            <span className="show-on-large">
                                                {isExpanded ? word.join(' ') : word.slice(0, maxWord).join(' ')}
                                                {!isExpanded && word.length > maxWord && '...'}
                                            </span>
                                            <span className="show-on-small">
                                                {isExpanded ? word.join(' ') : word.slice(0, 70).join(' ')}
                                                {!isExpanded && word.length > 70 && '...'}
                                            </span>
                                            {word.length > 70 && (
                                                <span className='text-primary pointer fs_12 fw-bold' onClick={(event) => handleClick(event, i)}>{isExpanded[i] ? ' See Less' : ' See More'}</span>
                                            )}
                                        </p>

                                        <div className='d-flex align-items-center bg-warning1 mt-4 position-relative'>
                                            <p className='Source_Regular m-0 text_gray fs_14'>Budget ₹{itm.budget}</p>
                                            <p className='Source_Regular m-0 text_gray fs_14 ms-2 flex-grow-1'>• {itm.totalProposals} Proposals</p>
                                        </div>

                                        <div className='d-flex bg-warning1 mt-3 position-relative'>
                                            {itm?.looking_for && itm?.looking_for.map((category, index) => (
                                                <p className='mr_5 Source_Regular fs_12 rounded-pill p-2 px-3 text-dark border_gray m-0 fw-normal badge' key={index}>{category}</p>
                                            ))}
                                        </div>
                                        {st && (
                                            <div className='center-me w-100 h-100 d-flex align-items-center justify-content-center'>
                                                <h5 className='fs-1 Source_Bold text-uppercase fs-xs-32'>DELETED</h5>
                                            </div>
                                        )}
                                    </Link>
                                </div>
                            </Col>
                        )
                    })}

                    {loadMore && !isLoading && list && list?.length > 0 && (
                        <div className='d_flex_align_justify_center  mt-4'>
                            <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                        </div>
                    )}

                    {!isLoading && list && list?.length == 0 && (
                        <div className='d_flex_align_justify_center flex-column mt-5 mb-2'>
                            <img src='/icons/not-found.png' alt='not foud' className='img-fluid h_100px w_100px mt-5' />
                            <h5 className='Source_SemiBold mt-4'>no data found</h5>
                        </div>
                    )}
                </Row>
                {/* {isShow && */}

                {/* } */}
            </Container>
        </div>
    )
}

export default MyQueries