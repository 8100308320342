import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { dec, enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { openInNewTab, useCountryGet } from '../../Componets/CommonFs';
import { useLocalStorage } from 'react-fu-hooks';
import { HowItWork } from '../../Componets/Modal';
import { useDispatch } from 'react-redux';
import { svTripCate } from '../../ContextRedux/Action';
import MessagingPopup from './MessagingPopup';
import { Loader } from '../../Componets/Loader';
import { Spin } from 'antd';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

const responsive_blog = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 6,
        slidesToSlide: 6
    },
    desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_review = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 2000, min: 1050 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1050, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_pkg = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 11,
        slidesToSlide: 8
    },
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 7
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 7,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 5,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 559 },
        items: 4,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 2
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 3,
        slidesToSlide: 3
    },
};

const responsive_type = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 11,
        slidesToSlide: 8
    },
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 7
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 7,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 5,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 559 },
        items: 4,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 2
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 2,
        slidesToSlide: 3
    },
};

const responsive_8 = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2200 },
        items: 7,
        slidesToSlide: 7
    },
    tablet: {
        breakpoint: { max: 2200, min: 968 },
        items: 5,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 967, min: 759 },
        items: 4,
        slidesToSlide: 4
    },
    xmobile: {
        breakpoint: { max: 759, min: 549 },
        items: 3,
        slidesToSlide: 3
    },
    xsmobile: {
        breakpoint: { max: 549, min: 0 },
        items: 2,
        slidesToSlide: 2
    },
};

const categories = [
    { id: 1, name: "Travel Package", imageUrl: "/images/ocean.png", route: 'packages' },
    { id: 2, name: "Hotels", imageUrl: "/images/island.png", route: 'hotels' },
    { id: 3, name: "Vehicle", imageUrl: "/images/Riding.png", route: 'vehicles' },
    { id: 4, name: "Guiders", imageUrl: "/images/Bag.png", route: 'guiders' },
];

function Home() {
    const { country } = useCountryGet();


    const carouselRef1 = useRef(null);
    const carouselRef3 = useRef(null);
    const carouselRef4 = useRef(null);
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setopen] = useState(false);
    const [blogs, setBlogs] = useState([])
    const [countries, setCountries] = useState([])
    const [hotelType, setHotelType] = useState([])
    const [remhotelType, setRemHotelType] = useState([])
    const [tripCate, setTripCate] = useState([])
    const [vehicleType, setVehicleType] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [locations, setLocations] = useState([])
    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // socketRef.current.emit('join', { user_id: userId1._id })
        getDetails();
    }, []);


    const getDetails = () => {
        setIsLoading(true)
        const send = {
            countryName: country
        }

        const enc1 = enc(send, key)
        CommonAPI(enc1, 'POST', `home`, responseBack)
    }

    const responseBack = useCallback((res) => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);

            setBlogs(dec1?.blog);
            setCountries(dec1?.country);
            setTripCate(dec1?.tripcategories);
            setHotelType(dec1?.hoteltype);
            setRemHotelType(dec1?.remHoteltype);
            setVehicleType(dec1?.vehicletype);
            setTestimonial(dec1?.testimonial);
            setLocations(dec1?.popularloc);

            dispatch(svTripCate(dec1?.tripcategories))
        } else {
        }
        setIsLoading(false)
    })

    const handlePrev = (type) => {
        if (type === 'pkg') {
            if (carouselRef1) {
                carouselRef1.current.previous();
            }
        } else if (type === 'blogs') {
            if (carouselRef3) {
                carouselRef3.current.previous();
            }
        } else {
            if (carouselRef4) {
                carouselRef4.current.previous();
            }
        }
    }

    const handleNext = (type) => {
        if (type === 'pkg') {
            if (currentIndex1 < tripCate.length - 1) {
                carouselRef1.current.next();
            }
        } else if (type === 'blogs') {
            if (currentIndex3 < blogs.length - 1) {
                carouselRef3.current.next();
            }
        } else {
            if (currentIndex4 < testimonial.length - 1) {
                carouselRef4.current.next();
            }
        }

    }

    const handleCallback = async (value, type) => {
        if (type === 'categoryId') {
            const update = { cID: '66a1e7b2e3e1ced4992a2617', month: [], location: '', categoryId: [value], cName: 'India' }
            localStorage.setItem('searchParams', JSON.stringify(update));
            goToPkgList()
        }
    }

    const goToPkgList = (type) => {
        const pkgHome = localStorage.getItem('searchParams');
        const pkgHomeParams = JSON.parse(pkgHome)

        openInNewTab('/package/list', pkgHomeParams)
    }

    const handleSave = (value, type) => {

        if (type === 'location') {
            setHotelLocation(value);
        } else {
            setHotelId(value);
        }
        window.open(`/hotels`, '_blank')
    };



    const [isModalVisible, setModalVisible] = useState(false);


    const videoUrl = "https://www.youtube.com/embed/D6Z8p7X3HTc?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fgo4explore.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1";

    // Use a unique key to force remount the iframe
    const iframeKey = isModalVisible ? "video" : "video-hidden";

    return (
        <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center bg-gray'>
            <div className='w_92 bg-succes1s mb-4 mt-5 d-flex align-items-center flex-column'>

                {/* Section 1 */}
                <Row className="bg-warning1 w-100">
                    <Col xxl={8} xl={7} lg={7} md={6} className="bg1-warning d-flex align-items-center flex-column justify-content-center pb-2">
                        <div className='bg1-secondary d-flex align-items-center flex-column  justify-content-center'>
                            <div className='Inter-Bold fs_40'>Say goodbye to endless  searching!</div>
                            <div className='Inter-Regular fs_14 text-secondary mt-2'>
                                Post your travel details and budget, and let verified vendors come to you with tailored offers that suit your style and financial needs. Your next adventure is just a bid away!
                            </div>

                            <div className='d-flex align-items-center bg1-danger pointer justify-center-xxl w-100 mt-3'>
                                <div className='mt-3 bg_thm h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => navigate('/post-travel')}>
                                    <p className='Rubik-Regular fs_12 text-white m-0'>Post Your Travel</p>
                                </div>
                                <div className='mt-3 bg-transparent border_thm pointer h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => setModalVisible(true)}>
                                    <p className='Rubik-Regular fs_12 text_thm  m-0'>How it works</p>
                                    <i className="fa-solid fa-play bg_thm rd_50 text-white p-1 fs_10 ms-2"></i>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className='bg-danger1 px-0'>
                        <img src={`/images/illus.png`} alt='home' className='img-fluid  h_380px' />
                    </Col>
                </Row>

                {isModalVisible && (
                    <div className="modalOverlay" onClick={() => setModalVisible(false)}>
                        <div className="modalContent" onClick={e => e.stopPropagation()}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <iframe
                                        frameBorder="0"
                                        allowFullScreen
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        title="Winter Spiti - An Unforgettable Journey"
                                        width="100%"
                                        height="100%"
                                        src={isModalVisible ? videoUrl : ""}
                                    />
                                </div>
                            </div>
                            <button className="closeButton" onClick={() => setModalVisible(false)}>Close</button>
                        </div>
                    </div>
                )}

                {/* Category Section */}
                <div className='d-flex align-items-center mt-6'>
                    <div className='Inter-Bold fs_24  flex-grow-1'>Categories</div>
                </div>

                <div className='Inter-Regular fs_14 text-secondary text-center mt-2'>
                    Here are lots of interesting destinations to visit, but don’t be confused—they’re already grouped by category.
                </div>


                <div className='w-100 d_flex_align_justify_center flex-wrap mt-2'>
                    {categories && categories?.map((item, index) => {
                        return (
                            <div key={index} className='mt-3 mb-4 rd_6 me-3 pointer'>
                                <Link to={`/${item.route}`} target='_blank' className='text-decoration-none hover-me d-flex align-items-center flex-column'>
                                    <img alt='img' src={item?.imageUrl} className='h_150px rd_47 mx-1 w_120px' />

                                    <div className='NunitoSans-SemiBold fs_14 text_dark_medium text-center mt-2'>{item?.name}</div>
                                </Link>
                            </div>
                        )
                    })}
                </div>

                {/* Map */}
                <div className='Inter-Bold fs_24 mt-5'>How it's works</div>

                <div className='bg-warning1 w-100 mt-4 d_flex_align_justify_center'>
                    <img src='/images/F1.png' className='img-fluid h_400px h-350px-md w-100-md w_70' alt='map' />
                </div>

                {/* Country Section */}
                <div className='Inter-Bold fs_24 mt-4'>Popular Countries</div>

                <div className='Inter-Regular fs_14 text-secondary mt-2 text-center'>
                    Here are lots of interesting destinations to visit, but don’t be confused
                </div>
            </div>

            <div className='w_92 mt-0'>
                {isLoading ? (
                    <Loader type={'country'} />
                ) : (
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive_8}
                        ssr={true}
                        autoPlay
                        infinite
                        autoPlaySpeed={2500}
                        keyBoardControl={true}
                        customTransition="transform 1000ms ease-in-out"
                        containerClass="carousel-container"
                        itemClass="carousel-item1"
                        customLeftArrow={
                            <CustomLeftArrow
                                direction="left"
                                mt={'mt-30px'}
                            />
                        }
                        customRightArrow={
                            <CustomLeftArrow
                                direction="right"
                                mt={'mt-30px'}
                            />
                        }
                        dotListClass="custom-dot-list-style"
                    >
                        {countries && countries?.map((item, index) => {
                            const slug = item.name.replace(/ /g, '-');

                            return (
                                <div key={index} className="mb-4 position-relative bg-white rd_30 mx-2-5 ">
                                    <Link to={`/country/${slug}/${item.id}`} state={{ id: item._id, type: 'country' }} className='text-decoration-none w-100'>
                                        <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                                        <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                            top: 0,
                                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                        }}>
                                            <div className='NunitoSans-Medium fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </Carousel>
                )}
            </div>

            <div className='w_92 bg-succes1s mb-5 mt-4 d-flex align-items-center flex-column'>

                {/* Package Section */}
                <div className='Inter-Bold fs_20 mb-2 flex-grow-1 mt-4 fs-xs-16'>Popular Packages Categories</div>

                <div className='w-100'>
                    {isLoading ? (
                        <Loader type={'category'} mt={'mt-2'} />
                    ) : (
                        <Carousel
                            responsive={responsive_pkg}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            // infinite={true}
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                    mt={'mt-30px'}
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                    mt={'mt-30px'}
                                />
                            }
                        >
                            {tripCate && tripCate?.map((item, index) => {

                                return (
                                    <div key={item._id} className='mt-3 mb-4 rd_6 mx-3 pointer w-xxs-90 hover-me d-flex align-items-center flex-column' onClick={() => handleCallback(item._id, 'categoryId')}>
                                        <img alt='img' src={item?.image ? `${img_base_url + item?.icon}` : `/images/ocean.png`} className='hw_55px' />

                                        <div className='NunitoSans-SemiBold fs_15 text_dark_medium text-center mt-2'>{item?.name}</div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    )}
                </div>


                {/* Benifits Section */}
                <Row className="bg-warning1 w-100 mt-6">
                    <Col className='bg-1danger px-0'>
                        <img src={`/images/illus1.png`} alt='home' className='img-fluid h_400px h-lg-380px' />
                    </Col>
                    <Col xl={7} lg={7} md={6} className="bg1-warning  pb-2">
                        <div className='bg1-secondary '>
                            <div className='Inter-SemiBold fs_29'>Benefits With Us</div>
                            <div className='Inter-Medium fs_14 text_gray mt-2'>
                                Discover a seamless travel experience with our exceptional hotels, convenient vehicle rentals, curated tour packages, and comprehensive trip planning services.
                            </div>

                            <div className='d-flex align-items-center p-2-5 rd_6 bg1-danger bg-white w-75 w-xxs-99 shadow-clean mt-3'>
                                <div className='rd_6 bg-white p-2 hw_50px' >
                                    <img src={`/icons/airplace.png`} alt='home' className='img-fluid hw_25px' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Exceptional Hotel Experiences</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Enjoy luxury stays, exclusive deals, and verified reviews at top-rated hotels.</div>
                                </div>
                            </div>
                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white p-2-5 hw_50px'>
                                    <img src={`/icons/hotel.png`} alt='home' className='img-fluid hw_25px' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Convenient Vehicle Rentals</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Access diverse, affordable vehicles with a seamless booking process and 24/7 support.</div>
                                </div>
                            </div>
                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white p-2-5 hw_50px'>
                                    <img src={`/icons/Group.png`} alt='home' className='img-fluid hw_25px' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Exceptional Hotel Experiences</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Experience handpicked tours with expert guides and all-inclusive options.</div>
                                </div>
                            </div>
                            <div className='shadow-clean-hover d-flex align-items-center p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                <div className='rd_6 bg-white p-2-5 hw_50px'>
                                    <img src={`/icons/car.png`} alt='home' className='img-fluid hw_25px' />
                                </div>
                                <div className='ms-3'>
                                    <div className='Inter-SemiBold fs_15'>Exceptional Hotel Experiences</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Plan your entire trip effortlessly with integrated services and real-time updates.</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>


                {/* Top Featured Hotels Section */}
                <div className='d-flex align-items-center flex-column mt-6 w-100'>
                    <div className='Inter-Bold fs_24'>Top Featured Hotels</div>

                    <div className='Inter-Regular fs_14 text-secondary mt-2 text-center'>
                        Sost Brilliant reasons Entrada should be your one-stop-shop!
                    </div>
                </div>
                <div className='d-flex align-items-center mt-3 pb-3 scrollable-container'>
                    {locations?.slice(0, 10).map((loc, index) => (
                        <div
                            key={index}
                            onClick={() => handleSave(loc.name, 'location')}
                            className={`mt-2 pointer fs_13 p-1 rounded-5 mx-1 px-3 Sansation_Regular text_gray2 border_white brc-secondary text-capitalize `}
                        >
                            {loc.name}
                        </div>
                    ))}
                </div>

                {isLoading ? (
                    <div className='w-100'>
                        <Loader type={'country'} />
                    </div>
                ) : (
                    <Row className='mt-4 w-100'>

                        <Col xl={3} lg={3} md={4} sm={5} xs={12} className="bg-warning1 mb-xs1-20">
                            <div className="mb-4 position-relative rd_30" onClick={() => handleSave(hotelType[0]?._id, '')}>
                                <img src={hotelType?.[0]?.image ? `${img_base_url + hotelType?.[0]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[0]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative rd_30" onClick={() => handleSave(hotelType?.[1]?._id, '')}>
                                <img src={hotelType?.[1]?.image ? `${img_base_url + hotelType?.[1]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[1]?.name}</div>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} lg={3} md={4} sm={7} className="bg-secondary1 mt">
                            <div className="mb-4 position-relative h-100 rd_30" onClick={() => handleSave(hotelType?.[2]?._id, '')}>
                                <img src={hotelType?.[2]?.image ? `${img_base_url + hotelType?.[2]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 pointer h-100' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[2]?.name}</div>
                                </div>
                            </div>
                        </Col>

                        <Col className="bg-warning1 d-flex align-items-center flex-wrap">
                            <div className="mb-4 mt-4-md me-2-md me-0-xs position-relative w-100-xs rd_30 w-100 w-49-md" onClick={() => handleSave(hotelType?.[3]?._id, '')}>
                                <img src={hotelType?.[3]?.image ? `${img_base_url + hotelType?.[3]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[3]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative rd_30 w_49 me-md-2 w-100-xs w-100-lg w-49-md" onClick={() => handleSave(hotelType?.[4]?._id, '')}>
                                <img src={hotelType?.[4]?.image ? `${img_base_url + hotelType?.[4]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>

                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[4]?.name}</div>
                                </div>
                            </div>
                            <div className="position-relative d-none-on-lg rd_30 w_49 d-inline-flex" onClick={() => handleSave(hotelType?.[5]?._id, '')}>
                                <img src={hotelType?.[5]?.image ? `${img_base_url + hotelType?.[5]?.image}` : `/images/hotel_1.jpg`} alt="hotelType" className='rd_15 w-100 h_200px pointer' />
                                <div className='rd_15 p-3 h-100 w-100 hoverthis d-flex position-absolute flex-column justify-content-end align-items-center pointer' style={{
                                    top: 0,
                                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(41, 38, 38,0.65)) ',
                                }}>
                                    <div className='Inter-Bold fs_15 text-white text-capitalize px-1'>{hotelType?.[5]?.name}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {isLoading ? (
                    <div className='w-100'>
                        <Loader type={'country'} />
                    </div>
                ) : (
                    <div className='w-100 mt-4'>
                        <Carousel
                            ref={carouselRef1}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex1(currentSlide)}
                            responsive={responsive_type}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={false}
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            arrows={false}
                        >
                            {remhotelType && remhotelType?.map((item, index) => {

                                return (
                                    <div key={item._id} className='mt-3 mb-4 rd_6 mx-2 pointer w-xxs-90 hover-me d-flex align-items-center flex-column' onClick={() => handleSave(item?._id, '')}>
                                        <img alt='img' src={item?.image ? `${img_base_url + item?.image}` : `/images/ocean.png`} className='h_130px rd_10 w_120px w-xxs-99' />

                                        <div className='NunitoSans-SemiBold fs_14 w-100 text_dark_medium text-center mt-2'>{item?.name}</div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    </div>
                )}

                {/* Review Section */}
                {testimonial && testimonial.length > 0 &&
                    <div className='d-flex align-items-center mt-7 w-100'>
                        <div className='Inter-Bold fs_20  flex-grow-1'>Our Clients Review</div>
                        <div className='bg-warning1 d-flex'>
                            <div onClick={() => handlePrev('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 ${currentIndex4 === 0 ? 'border border-dark' : 'bg-gradient-dark'}`}>
                                <i className='fas fa-angle-left' style={{ color: currentIndex4 === 0 ? '#000' : '#fff' }}></i>
                            </div>
                            <div onClick={() => handleNext('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 bg-gradient-dark`}>
                                <i className='fas fa-angle-right' style={{ color: '#fff' }}></i>
                            </div>
                        </div>
                    </div>}

                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef4}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex4(currentSlide)}
                            responsive={responsive_review}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={false}
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            arrows={false}
                        >
                            {testimonial && testimonial?.map((item, index) => {
                                return (
                                    <div key={item._id} className="reviews-box bg-white mb-4 mt-3 p-2-5 me-3 shadow-slowclean rd_10">
                                        <img src='/icons/review_user.png' alt="hotelType" className='rd_6 hw_55px' />
                                        <h5 className='Source_SemiBold spacing m-0 mt-3'>{item.name}</h5>
                                        <div className='NunitoSans-Regular text_gray fs_12 text-center m-0 mt-2'>
                                            {item.message}
                                        </div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>


                {/* Blogs Section */}
                <div className='d-flex align-items-center mt-6 ms-1 w-100'>
                    <div className='Inter-Bold fs_20  flex-grow-1'>Blogs</div>
                    <div className='bg-warning1 d-flex'>
                        <div onClick={() => handlePrev('blogs')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 ${currentIndex3 === 0 ? 'border_thm' : 'bg-gradient-thm'}`}>
                            <i className={`fas fa-angle-left ${currentIndex3 === 0 ? 'text_thm' : 'text-white'}`}></i>
                        </div>
                        <div onClick={() => handleNext('blogs')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 bg-gradient-thm`}>
                            <i className='fas fa-angle-right' style={{ color: '#fff' }}></i>
                        </div>
                    </div>
                </div>

                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef3}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex3(currentSlide)}
                            responsive={responsive_blog}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={false}
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "

                            arrows={false}
                        >
                            {blogs && blogs?.map((item) => {
                                return (
                                    <div key={item._id} className="reviews-box h_280px bg-white mb-4 mt-3 d-flex flex-column mx-2 shadow-slowclean position-relative rd_10 hover_btt ">
                                        <Link to={`/blog/${item.slug}/${item.id}`} target='_blank' className='text-decoration-none hover-me d-flex justify-content-center flex-column'>
                                            <img src={`${img_base_url + item?.image}`} alt="hotelType" className='rd_tl_tr_6 w-100 h_165px pointer' />
                                            <h5 className='Source_SemiBold text_gray fs_15 px-2 fs-xs-14 spacing pointer m-0 mt-2 text-capitalize mini-line-1'>{item.title}</h5>
                                            <h5 className='Inter-Regular text-secondary fs_12 px-2 spacing pointer m-0 mt-2 text-capitalize mini-line-1 two'>{item.sortDescription}</h5>
                                            <p className='m-0 Source_Regular px-2 d-flex align-items-center mt-2-5 text_thm fs_14 pointer move_ltr pb-2'>Read More <i className='fa-solid fa-angle-right ms-2 fs_12'></i></p>
                                        </Link>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>

            </div>

            {/* <MessagingPopup type={'common'} /> */}

            <HowItWork isVisible={open} onClose={setopen} />
        </div>
    )
}

export default Home