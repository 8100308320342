import React from 'react';

const RatingStars = ({ rating, size }) => {
    const fullStars = Math.floor(rating);
    const half = rating - fullStars >= 0.5
    // console('fullStar', fullStars, 'half', half);
    let stars = [];

    for (let i = 0; i < 5; i++) {
        if (i < fullStars) {
            stars.push(<i className="fa-solid fa-star text_thm fs_13"></i>)
        } else if (half && (i === fullStars)) {
            stars.push(<i className="fa-solid fa-star-half text_thm fs_13"></i>)
        } else {
            stars.push(<i className="fa-solid fa-star text_lightgray fs_13"></i>)
        }
    }
    
    return stars
}
export default RatingStars

{/* <Icon name="star" color={themeDark} size={size ? size : 12} /> */}
{/* <Icon name="star-half-full" color={themeDark} size={size ? size : 12} /> */}