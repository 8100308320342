import React from 'react'
import { Container } from 'react-bootstrap'
import Tabs from './Tabs'

function MyBookings() {
    return (
        <div className='mt-12 pt-3 p-0 pb-5'>
            <Container className="bg-warning1 mt-3">

                <Tabs/>
            </Container>
        </div>
    )
}

export default MyBookings