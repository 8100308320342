import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Checkbox, Radio } from 'antd';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { Slider } from '@mui/material';
import { useCountryGet } from '../../Componets/CommonFs';
const key = process.env.REACT_APP_KEY;

export function FilterHotels({ selectedValues, selectedValues1, hotelType, onChange }) {
    const [filter4, setFilter4] = useState(true);
    const [filter5, setFilter5] = useState(true);

    const [list, setList] = useState([])
    const [list1, setList1] = useState([])
    const [sliceAll, setSliceAll] = useState(5)
    const [sliceAll1, setSliceAll1] = useState(5)
    const [value, setValue] = useState([100, 100000]);



    useEffect(() => {
        getList()
    }, []);

    const getList = (id) => {
        CommonAPI({}, 'GET', `amenityList`, responseBack)
        CommonAPI({}, 'GET', `hotelTypeList`, responseBack1)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res?.data
            const dec1 = dec(dt, key)
            setList(dec1)
        }
    })

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const dt = res?.data
            const dec1 = dec(dt, key)
            setList1(dec1)
        }
    })

    const handleCallback = async (event, newValue) => {
        onChange(newValue, 'rng')
        setValue(newValue)
    }

    const filterName = list1.filter((item) => hotelType.includes(item._id))



    return (
        <div className={`bg-warning1 bg-white rd_6 p-4 h-auto`}>

            <p className='Source_SemiBold mb-2 fs_14'>Price Range</p>
            <Slider
                getAriaLabel={() => 'Price Range'}
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                onChangeCommitted={(event, newValue) => handleCallback(event, newValue)}
                valueLabelDisplay='off'
                min={100}
                max={100000}
                className='ms-2 w_95'
                sx={{
                    color: '#089dca', // Slider range color
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#089dca', // Thumb color
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: '#089dca', // Track color
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Rail color (optional)
                    },
                }}
            />
            <div className='d-flex justify-content-between p-0' style={{ marginTop: -5 }}>
                <div
                    className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                >
                    {value[0]}
                </div>
                <div
                    className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                >
                    {value[1]}
                </div>
            </div>

            <p className='Source_SemiBold mb-2 fs_14 pointer mt-3' onClick={() => setFilter5(!filter5)}>Hotel Types <span className='bi bi-caret-down-fill text_gray fs_12 ms-1'></span></p>
            {filterName?.length > 0 && (
                <div className='d-flex gap-2 mt-3 mb-3 scrollable-h scrollable'>
                    {filterName?.map((item) => (
                        <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => onChange(item._id, 'other')}>
                            <p className='m-0 fs_11'>{item.name}</p>
                            <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                        </div>
                    ))}
                </div>
            )}

            {filter5 && <>
                {list1.slice(0, sliceAll1).map((item) => {
                    return (
                        <label key={item._id} className='Source_Regular text-capitalize mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                            <input
                                type="checkbox"
                                className='mr_5 '
                                checked={hotelType.includes(item._id)}
                                onChange={() => onChange(item._id, 'other')}
                            />
                            {item.name}
                        </label>
                    )
                })}
                {list1 && list1.length >= 5 && <p className='Source_SemiBold mb-2 fs_12 pointer mt-2-5 text-primary' onClick={() => { setSliceAll1(sliceAll1 == 100 ? 5 : 100) }}>See {sliceAll1 == 100 ? 'less' : 'all'}</p>}

            </>}

            <p className='Source_SemiBold mb-2 fs_14 pointer mt-2-5' onClick={() => setFilter4(!filter4)}>Amenities <span className='bi bi-caret-down-fill text_gray fs_12 ms-1'></span></p>

            {filter4 && <>
                {list.slice(0, sliceAll).map((item) => {
                    return (
                        <label key={item._id} className='Source_Regular text-capitalize mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                            <input
                                type="checkbox"
                                className='mr_5 '
                                checked={selectedValues.includes(item._id)}
                                onChange={() => onChange(item._id, 'categoryId')}
                            />
                            {item.name}
                        </label>
                    )
                })}
                {list && list.length >= 5 && <p className='Source_SemiBold mb-2 fs_12 pointer mt-2-5 text-primary' onClick={() => { setSliceAll(sliceAll == 40 ? 5 : 40) }}>See {sliceAll == 5 ? 'all' : 'less'}</p>}
            </>}

        </div>
    )
}


export function FilterPackages({ onChange, selectedValues, locations, country }) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonth = new Date().getMonth();

    const ordersMonth = [
        ...months.slice(currentMonth),
        ...months.slice(0, currentMonth)
    ]

    const [filter, setFilter] = useState(true);
    const [filter2, setFilter2] = useState(true);
    const [selected, setSelected] = useState('');
    const [value, setValue] = useState([100, 100000]);
    const { country1 } = useCountryGet();

    const categories = useSelector(reducer => reducer.data.category);

    const starting = selectedValues?.cName == '' ? country1 : selectedValues?.cName
    const cName = country == '' ? starting : country

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        // onChange('SEP', 'month');
        setSelected(ordersMonth[0]);
    }

    const handleCallback = async (event, newValue) => {
        onChange(newValue, 'rng')
        setValue(newValue)
    }


    return (
        <div className={`bg-warning1 bg-white rd_6 p-4 h-auto`}>
            <p className='Source_SemiBold mb-2 fs_14'>Price Range</p>
            <Slider
                getAriaLabel={() => 'Price Range'}
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                onChangeCommitted={(event, newValue) => handleCallback(event, newValue)}
                valueLabelDisplay='off'
                min={100}
                max={100000}
                className='ms-2 w_95'
            />
            <div className='d-flex justify-content-between p-0' style={{ marginTop: -5 }}>
                <div
                    className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                >
                    {value[0]}
                </div>
                <div
                    className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                >
                    {value[1]}
                </div>
            </div>


            {locations.length > 0 && (
                <>
                    <p className='Source_SemiBold mb-2 fs_14 mt-3 pointer' onClick={() => setFilter(!filter)}>Popular Places in {cName}</p>
                    <Radio
                        value={'All'}
                        onChange={() => onChange('', 'location')}
                        checked={selectedValues.location == ''}
                        className="fs_14 Source_Regular mb-1"
                    >
                        All
                    </Radio>
                    {locations?.map((item) => {
                        return (
                            <label key={item._id} className='Source_Regular mb-1 text_gray fs_14 d-flex align-items-center pointer'>
                                <Radio
                                    value={item.name}
                                    onChange={() => onChange(item._id, 'location')}
                                    checked={selectedValues.location == item._id}
                                    className="fs_14 Source_Regular"
                                >
                                    {item.name}
                                </Radio>
                            </label>
                        )
                    })}
                </>
            )}

            <p className='Source_SemiBold mb-2 fs_14 mt-3'>Month wise</p>
            <div className='d-flex align-items-center pb-3 overflow-hidden flex-wrap'>
                {ordersMonth.map((month, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => { onChange(month, 'month', 'other'); setSelected(month); }}
                            className={`mt-2 pointer fs_12 p-1 rounded-5 mx-1 px-3 ${selectedValues?.month?.includes(month) ? 'Source_Medium current-month text-white bg_thm' : 'Sansation_Regular text_gray2 border_white brc-secondary'}`}
                        >
                            {month}
                        </div>
                    )
                })}
            </div>

            <p className='Source_SemiBold mb-2 fs_14 pointer mt-2-5' onClick={() => setFilter2(!filter2)}>Trip Categories <span className='bi bi-caret-down-fill text_gray fs_12 ms-1'></span></p>

            {
                filter2 && <>
                    {categories.map((item) => {
                        return (
                            <label key={item._id} className='Source_Regular mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                                {/* <Radio
                                    value={item.name}
                                    onChange={() => onChange(item._id, 'categoryId', 'other')}
                                    checked={'66eeb5bb02b64892cd9c799b' == item._id}
                                    className="fs_14 Source_Regular"
                                >
                                    {item.name}
                                </Radio> */}
                                <Checkbox checked={selectedValues?.categoryId.includes(item._id)}
                                    onChange={() => onChange(item._id, 'categoryId', 'other')} className='fs_14 Source_Regular'>
                                    {item.name}
                                </Checkbox>
                            </label>
                        )
                    })}
                </>
            }
        </div >
    )
}
