import React from 'react';

const CustomArrow = ({ onClick, direction }) => {
  return (
    <button className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--${direction} `} onClick={onClick}>
      {/* {direction === 'left' ? '<' : '>'} */}
    </button>
  );
};

export default CustomArrow;

export const CustomLeftArrow = ({ onClick, direction, mt }) => {
  const arrowStyles = {
    [direction === 'left' ? 'left' : 'right']: 0,
  };
  return (
    <button style={arrowStyles} className={`custom_arrow rounded react-multiple-carousel__arrow text-white ${direction} ${mt}`} onClick={onClick}>
      {direction === 'left' ?<i className='fas fa-angle-left text_thm'></i>:<i className='fas fa-angle-right text_thm' ></i>}
    </button>
  );
};

export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slick-next me-4 custom_arrow_slick slick-arrow rd_6 hw_30px h_35px d-flex align-items-center justify-content-center`}
      style={{
        ...style,

      }}
      onClick={onClick}
    >
      <i className='fas fa-angle-right fs_15' onClick={onClick} style={{ color: '#007964' }}></i>
    </div>
  );
}

export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={`slick-prev ms-4 custom_arrow_slick slick-arrow rd_6 hw_30px h_35px d-flex align-items-center justify-content-center`}
      style={{
        ...style,

      }}
      onClick={onClick}
    >
      <i className='fas fa-angle-left fs_15' style={{ color: '#007964' }}></i>
    </div>
  );
}