import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import OffcanvasNav from '../Componets/Offcanvas';
import { MenuModal } from '../Componets/Modal';
import { MemContext } from '../ContextRedux/Context';
import SignInModal from '../Pages/Auth/SignInModal';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const vendor_url = process.env.REACT_APP_VENDOR_URL;

function Nav(props) {
    const user1 = useSelector(state => state.data.info)
    const { isLoading, unId } = useContext(MemContext);
    const type = props?.type

    const [isSticky, setIsSticky] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);



    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingUp = prevScrollPos > currentScrollPos;

            if (isScrollingUp && currentScrollPos > 100) { // Add a threshold if needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const handleMailClick = (e) => {
        e.preventDefault()
        window.location.href = 'mailto:support@travellinglark.com';
    };

    const serv = user1 && user1?.user?.services
    


    return (
        <>
            <nav className={`navbar-expand-lg z-2 navbar-light p-0 navbar d-flex flex-column bg-gray ${type == 'queryDetails' ? '' : isSticky ? 'sticky' : ''}`}>
                <nav className={`custom-nav z-3 text-white d-flex align-items-center h_45px w-100 bg_thm ${type == 'queryDetails' ? '' : isSticky ? 'd-none' : ''}`}>
                    <i className="fa-solid fa-envelope ps-20px"></i>
                    <div className='NunitoSans-Regular fs_14 ms-2 pointer' onClick={handleMailClick}>support@travellinglark.com</div>

                    <div className='flex-grow-1' />
                    <Link to={vendor_url} className='Inter-Regular text-white pointer fs_14 d-none-on-md'>Travel Agent Zone</Link>
                    {serv?.includes('packages') && <Link to={user1?.tkn ? `${vendor_url}redirect/view-packages/${user1?.tkn}` : `/signin`} target={user1.tkn ? `_blank` : ''} className='Inter-Regular text-white pointer fs_14 d-none-on-md ms-4'><i className="fa-solid fa-plus fs_12"></i> List Your Package</Link>}
                    {serv?.includes('hotels') && <Link to={user1?.tkn ? `${vendor_url}redirect/hotel/${user1?.tkn}` : `/signin`} target={user1.tkn ? `_blank` : ''} className='Inter-Regular text-white pointer fs_14 d-none-on-md ms-4'><i className="fa-solid fa-plus fs_12"></i> List Your Hotels</Link>}
                </nav>
                <nav className={`custom-nav navbar-expand-lg z-3 navbar-light p-0 navbar h_65px position-relative`} style={{ background: '#f9f9f9' }}>
                    <Link to="/" className="navbar-brand bg-warning1 d-flex align-items-center justify-content-center px-4 px-lg-1">
                        <img src="/icon1.png" alt="TravellingLark" className="logo_footer me-1 hw_55px " />
                        <div className="m-0 text_thm NunitoSans-ExtraBold gradient-text">TravellingLark</div>
                    </Link>
                    <button type="button" className="navbar-toggler me-4 offcanvas-custom" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        onClick={toggleOffcanvas}>
                        <span className="navbar-toggler-icon fs_14"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto p-4 p-lg-0">
                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/' className={(a) => a.isActive ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Home</p>
                                </NavLink>
                            </li>

                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/post-travels' className={(a) => a.isActive || window.location.pathname.startsWith('/post') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Travel Posts</p>
                                </NavLink>
                            </li>

                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/packages' className={(a) => a.isActive || window.location.pathname.startsWith('/package') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Packages</p>
                                </NavLink>
                            </li>
                            <li className="nav-item bg-warning1 d-flex align-items-center">
                                <NavLink to='/hotels' className={(a) => a.isActive || window.location.pathname.startsWith('/hotel') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Hotels</p>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item d-flex align-items-center">
                                <NavLink to='/coming-soon' className={(a) => a.isActive || window.location.pathname.startsWith('/vehicles') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Vehicle</p>
                                </NavLink>
                            </li>
                            <li className="nav-item bg-warning1 d-flex align-items-center">
                                <NavLink to='/guiders' className={(a) => a.isActive || window.location.pathname.startsWith('/guider') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Guider</p>
                                </NavLink>
                            </li> */}

                            {isLoading == true ? (
                                <li className="nav-item bg-warning1 pt-0-5">
                                    <Spin className='thm-spin' />
                                </li>
                            ) : (
                                !unId && unId == null && (
                                    <li className="nav-item d-flex align-items-center" >
                                        <p onClick={openModal} className='m-0 NunitoSans-Bold fs_15 text_gray pointer'>Login</p>
                                    </li>
                                ))}
                        </ul>

                        <li className="nav-item d-flex align-items-center ms-3" >
                            <NavLink to='/post-travel' className={(a) => a.isActive || window.location.pathname.startsWith('/quer') ? 'nav-link active' : 'nav-link'}>
                                <div className="nav-link text-white login-nav-link p-1 px-3 NunitoSans-Regular bg_thm fs_13 d_flex_align_justify_center h_35px rd_50 pointer">Post Your Travel</div>
                            </NavLink>
                        </li>

                        {unId && unId !== null && (
                            <div className="nav-item bg-warning1 pt-0-5">
                                <MenuModal />
                            </div>
                        )}
                    </div>
                </nav>
            </nav >

            {/* Offcanvas Slider open when click on menu toggle button */}
            < OffcanvasNav show={showOffcanvas} handleClose={toggleOffcanvas} />

            {/* Modal Sigin */}
            < SignInModal isOpen={isModalOpen} onClose={closeModal} />
        </>

    )
}

export default Nav