import React, { createContext, useContext, useState, useEffect } from 'react'

export const MemContext = createContext();


function Context({ children }) {
    const [isLoading, setIsLoading] = useState(true)
    const [unId, setunId] = useState(null)
    const [userInfo, setUserInfo] = useState(null)


    const login = (data, unId) => {
        setIsLoading(true);
        let userInfo = data
        localStorage.setItem('userD', JSON.stringify(data))
        localStorage.setItem('unId', unId)
        setUserInfo(userInfo);
        setunId(unId);
        setIsLoading(false);
    }

    const logout = async () => {
        setIsLoading(true);
        setunId(null);
        setUserInfo(null);
        
        localStorage.removeItem('userD');
        localStorage.removeItem('unId');
        setIsLoading(false);
    }

    useEffect(() => {
        isLoggedIn();
    }, []);

    const isLoggedIn = async () => {
        try {
            setIsLoading(true);

            let userInfo = await localStorage.getItem('userD');
            let unId = await localStorage.getItem('unId');
            userInfo = JSON.parse(userInfo);

            if (userInfo) {
                setUserInfo(userInfo);
                setunId(unId);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }

    return (
        // <MemContext.Provider value={{ user1, setUser1 }}>
        <MemContext.Provider value={{
            login,
            logout,
            isLoading,
            unId,
            // error,
            userInfo,
        }}>
            {children}
        </MemContext.Provider>
    )
}

export default Context

// export const MemUser = () => useContext(MemContext)