import { Slider } from '@mui/material';
import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import { CountriesDrop } from '../../../Componets/Countries';

function FilterQuery({ selectedValues, preference, selectedValues1, onChange }) {
    const [filter6, setFilter6] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [value, setValue] = useState([100, 100000]);
    const [selecetd, setSelected] = useState('');

    useEffect(() => {
        getList()
    }, [])

    const getList = () => {
        const parse = localStorage.getItem('countriesList');
        const list = JSON.parse(parse);
        setSelectedCountry(list)
    }

    const filter = selectedCountry?.filter(item => preference?.includes(item._id))

    const handleChange =(res)=>{
        setSelected(res)
        onChange(res)
    }

    // console.log('selecte', selectedValues);
    



    return (
        <div className='bg-warning1 bg-white rd_6'>
            <p className='fw-bold m-0 p-2-5 d-none-on-xs fs_15'>FILTER</p>
            <div className='divider d-none-on-xs'></div>
            <div className='ps-4 pt-2 pb-2'>

                <p className='Rubik-Medium mb-0 fs_14'>Price Range</p>
                <Slider
                    getAriaLabel={() => 'Price Range'}
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    onChangeCommitted={(event, newValue) => onChange(newValue, 'rng')}
                    valueLabelDisplay='off'
                    min={100}
                    max={100000}
                    className='w_92'
                    sx={{
                        color: '#089dca', // Slider range color
                        '& .MuiSlider-thumb': {
                            backgroundColor: '#089dca', // Thumb color
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#089dca', // Track color
                        },
                        '& .MuiSlider-rail': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Rail color (optional)
                        },
                    }}
                />

                <div className='d-flex justify-content-between p-0 w_92' style={{ marginTop: -5 }}>
                    <div
                        className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                    >
                        {value[0]}
                    </div>
                    <div
                        className="bg_lightgray Rubik-Regular text-secondary d-flex align-items-center fs_14 px-2 rd_6 h_30px"
                    >
                        {value[1]}
                    </div>
                </div>

                <p className='Rubik-Medium mb-2 fs_14 mt-4'>Country</p>
                <div className='bg-warning1 p-0 w_20 mt-2 mt-sm-0 w_92'>
                    <CountriesDrop onChange={handleChange} selected={selecetd || ''} type={'query'} />
                </div>

                {filter && filter?.map((item) => {
                    return (
                        <label key={item._id} className='Source_Regular mb-2 text_gray mt-2 fs_14 d-flex align-items-center pointer'>
                            <Checkbox checked={selectedValues.includes(item._id) || selectedValues?.length === 0}
                                onChange={() => onChange(item._id, 'cID')} className='fs_14 Source_Regular'>
                                {item.name}
                            </Checkbox>
                        </label>
                    )
                })}

                <p className='Rubik-Medium mb-2 fs_14 pointer mt-4' onClick={() => setFilter6(!filter6)}>Looking For <span className='bi bi-caret-down-fill text_gray fs_12 ms-1'></span></p>

                {filter6 && <>
                    <label className='Source_Regular mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                        <Checkbox checked={selectedValues1.includes('packages')}
                            onChange={() => onChange('packages', 'pkg')} className='fs_14 Source_Regular'>
                            Packages
                        </Checkbox>
                    </label>
                    <label className='Source_Regular mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                        <Checkbox checked={selectedValues1.includes('guider')}
                            onChange={() => onChange('guider', 'pkg')} className='fs_14 Source_Regular'>
                            Guider
                        </Checkbox>
                    </label>
                    <label className='Source_Regular mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                        <Checkbox checked={selectedValues1.includes('vehicle')}
                            onChange={() => onChange('vehicle', 'pkg')} className='fs_14 Source_Regular'>
                            Vehicle
                        </Checkbox>
                    </label>
                    <label className='Source_Regular mb-2 text_gray fs_14 d-flex align-items-center pointer'>
                        <Checkbox checked={selectedValues1.includes('hotel')}
                            onChange={() => onChange('hotel','pkg')} className='fs_14 Source_Regular'>
                            Hotel
                        </Checkbox>
                    </label>
                </>
                }

            </div>
        </div>
    )
}

export default FilterQuery