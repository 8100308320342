import { Checkbox, DatePicker, Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import { Hotels } from '../../Componets/Images';
import SearchCabLoc, { PassengersModal } from './SearchCabLoc';
import { FormVehicleModal, ParagraphModal } from '../../Componets/Modal';
import { dec } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import parse from 'html-react-parser';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

function VehicleList() {
    const [passengers, setPassengers] = useState(1);
    const [dates, setDates] = useState([])
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState({open:false, terms:''})

    const location = useLocation();
    const val = location?.state

    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValues1, setSelectedValues1] = useState([]);

    useEffect(() => {
        getList(page);
    }, [page, search.length >= 3 && search]);

    const getList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `vehicleList?countryId=${val.cId}&type=${val.categoryId}&page=${page}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt?.data, key)
            if (dec1 == undefined || dec1 == null) {
                setList(dec1)
            } else {
                setList(prevData => {
                    const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                    return [...prevData, ...newData];
                });
            }

            if (dec1.length < 10) {
                setLoadMore(false);
            }

        }
        setIsLoading1(false)
        setIsLoading(false)
    })


    const handleShowMore = () => {
        setIsLoading1(true)
        setPage(prevPage => prevPage + 1)
    };

    const handleDateChange = (date) => {
        if (date) {
            setDates(date);
        } else {
            setDates(null);
        }
    };


    const termsCharges = `
- Vehicle and fuel charges included

- Driver Night Charges included

- For Round trip bookings, all the local sightseeing in the destination cities is included except for Sikkim and Darjeeling. For local sightseeing in Pickup city (if required), please add all the Pickup city stops in itinerary.

- 5% GST Extra

- Included Kilometers will start from pickup location

- Driver allowance includes driver's stay, food and night charges

- Toll and state tax extra

- Parking charges extra if applicable

- AC will remain switch off in hill areas

- For round trip booking, Kilometers will count from pickup location to pickup location

- One way trip includes only one pickup and one drop. Additional pickup or drop on the way will incur additional charges of Rs. 250 per pickup / drop.

- Rohtang Pass needs special permit and is not included in any trip
    `
    const limit = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    // 

    return (
        <div className='mt-6 pt-3 p-0 pb-5' style={{ backgroundColor: '#fff' }}>

            <Container fluid className='mb-4 pt-5 pb-3' style={{ backgroundColor: '#eef4fd' }}>
                {/* Filter */}
                <div className='container d-flex align-items-center p-3 bg-white rd_6'>
                    <div className='b gap-2 d-flex align-items-end bg1-warning d-none-on-lg mr_10' >
                        <div>
                            <label className='Source_Medium text_gray fs_12 mb-2 d-block'>From</label>
                            <input placeholder='rishikesh' className='Source_Regular text-secondary w-auto outline-none fs_13 px-2 p-1-5 border_gray rd_6 ' />
                        </div>
                        <div>
                            <label className='Source_Medium text_gray fs_12 mb-2 d-block'>To</label>
                            <input placeholder='delhi' className='Source_Regular text-secondary w-auto outline-none fs_13 px-2 p-1-5 border_gray rd_6 ' />
                        </div>
                        <div>
                            <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Travel Date</label>
                            <DatePicker
                                className='fs_12 text_gray '
                                onChange={handleDateChange}
                                value={dates}
                            />
                        </div>
                        <div>
                            <label className='Source_Medium text_gray fs_12 mb-2 d-block'>Passengers</label>
                            <div className='Source_Regular fs_13 bg-white px-4 text-secondary p-1-5 border_gray rd_6 pointer' onClick={() => setOpen1(true)}>{passengers} Passengers</div>
                        </div>
                        <div className='Source_Medium p-1 px-2 bg_thm text-white text-center rd_6 m-0 w_150px pointer'>
                            Search
                        </div>
                    </div>
                    <p className='Source_Medium text_thm p-1 px-2 rd_6 fs_14 bg_thmlight pointer m-0 d-lg-none mr_10 ' onClick={() => setOpen2(true)}><i className='bi bi-search fs_13 mr_5'></i> Search</p>
                </div>
            </Container>

            <Container className="bg-warning1 mt-3">
                <Row className='bg-primary1 mt-3'>
                    {/* Listing */}
                    {list.map((item) => {
                        const terms = parse(item.details);
                        
                        return(
                            <Col xs={6} sm={6} md={4} lg={3} className="m-xxs-auto mb-4 w-xxs-90 bg-warning1">
                                <div className='mb-xs-10 shadow-n1 bg-white rd_6 p-0 overflow-hidden position-relative'>
                                    <div className='bg-white p-2'>
                                        <img src={`${img_base_url + item.image}`} className='img-fluid h_120px w-100 position-relative bg-white overflow-hidden' />
                                    </div>
                                    <div className='p-1 px-2' style={{
                                        position: 'absolute',
                                        top: 0,
                                        width: 'auto',
                                        height: 'auto',
                                        borderBottomRightRadius: 10,
                                        background: 'rgba(0,0,0,0.6)'
                                    }}>
                                        <p className='Source_Regular text-white fs_12 m-0'>Starting ₹8932</p>
                                    </div>
                                    <h3 className='Source_SemiBold text_gray fs_15 fs-xs-14 spacing ps-2 pt-2'>{item.name}</h3>
    
                                    <div className="p-2 px-2-5 overflow-hidden position-relative">
                                        <div className='d-flex align-items-center Source_Medium'>
                                            <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Seat Included</p>
                                            <p className='fs_14 bg-warning1 m-0 text_gray2'>{item.sheets}</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium'>
                                            <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Luggage Bag</p>
                                            <p className='fs_14 bg-warning1 m-0 text_gray2'>18</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium'>
                                            <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Driver Charges</p>
                                            <p className='fs_14 bg-warning1 m-0 text_gray2'>Included</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium'>
                                            <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Per KM</p>
                                            <p className='fs_14 bg-warning1 m-0 text_gray2'> ₹{item.charges}</p>
                                        </div>
    
                                        <label className='Source_Regular text-info fs_12 mb-1 pointer' onClick={() => {setOpen4({open:true, terms:terms})}}>Other Terms & Charges</label>
    
                                        <div className='pointer p-1 mt-2 rd_50 h-xxs-35px border_thm text_thm Source_Medium d-flex align-items-center justify-content-center' onClick={() => setOpen3(true)}>
                                            Book Now
                                        </div>
                                    </div>
    
                                </div>
                            </Col>
                        )
                    })}
                </Row>

                <Row className='bg-warning1  p-2 px-0'>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op1.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">Save up to 30%</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    We will find you the cheapest airport transfers with our verified partners!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op2.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">Professional drivers</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    Experienced and polite drivers with well-serviced, comfortable cabs.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op3.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">24/7 Customer Support</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    We are always available to help you with your transfer
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className='bg-light p-3 rd_6  mt-5'>
                    <h2 className="Source_Bold fs_15 mb-0">Cab from Rishikesh</h2>
                    <div className='d-flex gap-3 text-center flex-wrap mt-4'>
                        {limit.map((item) => (
                            <p className='Source_Regular fs_12 pointer m-0 text-center'>Cabs from Rishikesh to Delhi</p>
                        ))}
                    </div>
                </div>


                {/* Other Charges */}
                <ParagraphModal isVisible={open4} onClose={setOpen4}/>

                {/* Passengers Modal */}
                <PassengersModal isVisible={open1} onClose={setOpen1} pase={passengers} setPase={setPassengers} />

                {/* Search Destination Cab */}
                <SearchCabLoc isVisible={open2} onClose={setOpen2} />

                {/* From */}
                <FormVehicleModal isVisible={open3} onClose={setOpen3} />
            </Container>
        </div>
    )
}

export default VehicleList