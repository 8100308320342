import { tstError } from "./HotToast";

const local_url = process.env.REACT_APP_LOCAL_URL
const base_url = process.env.REACT_APP_BASE_URL


export const CommonAPI = async (data, type, EndPoint, callback) => {

    try {
        const unId = await localStorage.getItem('unId');

        if (type == 'POST') {
            await fetch(base_url + EndPoint, {
                method: 'POST',
                headers: {
                    Accept:'application/json',
                    'Content-Type':'application/json',
                    ...(unId ? { Authorization: `Bearer ${unId || ''}`} : {})
                },
                body: JSON.stringify({ encryptedData: data })
            })
                .then((res) => res.json())
                .then((data) => {
                    callback(data)
                })
                .catch((err) => {
                })
        } else {
            await fetch(base_url + EndPoint, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...(unId ? {Authorization: `Bearer ${unId || ''}`} : {})
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    callback(data)
                })
                .catch((err) => {
                })
        }
    } catch (error) {
    }
}