import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationData, setNotificationData] = useState(null);

    const showNotification = (data) => {
        setNotificationData(data);
        setNotificationVisible(true);

        setTimeout(() => {
            setNotificationVisible(false);
        }, 3000);
    };

    return (
        <NotificationContext.Provider value={{ notificationVisible, notificationData, showNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
