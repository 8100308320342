// Map.js
import React from 'react';

const Map = ({ address }) => {
  const mapUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}&output=embed`;

  return (
    <iframe
      width="100%"
      height="330"
      style={{ border: 0 }}
      loading="lazy"
      allowFullScreen
      src={mapUrl}
      className='rd_6 mt-3'
      title="Google Map"
    ></iframe>
  );
};

export default Map;
