import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { tstSuccess } from '../../Componets/HotToast';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { svInfo } from '../../ContextRedux/Action';
import { MemContext } from '../../ContextRedux/Context';
import { useDispatch } from 'react-redux';
import { CountriesDrop } from '../../Componets/Countries';
import { Spin } from 'antd';

const key = process.env.REACT_APP_KEY;

export function Overview({ user }) {
  const { login } = useContext(MemContext);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [num, setNum] = useState(user?.phone || '');
  const [address, setAddress] = useState(user?.address || '');
  const [email, setEmail] = useState('');
  const [err, setErr] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [isEnable, setIsEnable] = useState(false);

  const dispatch = useDispatch();
  const [country, setCountry] = useState('')

  useEffect(() => {
    const isChanged = firstName !== user?.first_name ||
      lastName !== user?.last_name ||
      Number(num) !== user?.phone ||
      address !== user?.address ||
      country !== user?.countryId?._id

    setIsEnable(isChanged)
  }, [firstName, lastName, country, address, num])
  

  useEffect(() => {
    setFirstName(user?.first_name || '');
    setLastName(user?.last_name || '');
    setNum(user?.phone || '');
    setAddress(user?.address || '');
  }, [user])

  const handleSubmit = (e) => {
    e.preventDefault();

    setErr('')
    const data = {
      userId: user?._id,
      first_name: firstName,
      last_name: lastName,
      phone: num,
      address: address,
      countryId:country
    };
    const enc1 = enc(data, key)


    if (/^\d{10}$/.test(num)) {
      setIsLoading(true)
      CommonAPI(enc1, 'POST', 'updateProfile', responseBack)
    } else {
      setErr('Please enter a valid 10-digit phone number.')
    }
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res.data, key)
      dispatch(svInfo({ user: dec1, tkn: res.token }))
      login(dec1, res.token)
      setIsEditing(!isEditing)
      tstSuccess('Profile Updated Successfully')
    }
    setIsLoading(false)
  })

  // const 

  return (
    <Container className='bg-primary1 p-0'>
      <Row>
        <Col lg={9}>
          <div className='rd_6 p-3 bg-white shadow-slowclean mt-4'>
            <div className='d-flex align-items-center'>
              <h3 className='fw-bolder fs_16 m-0 flex-grow-1'>Profile Details</h3>
              {/* {!isEditing && user?.role == 'user' && ( */}
                <div onClick={() => setIsEditing(!isEditing)} className='btn bg_thm text-white fs_14'>
                  Edit Profile
                </div>
              {/* )} */}
            </div>
            <div className='divider mt-2'></div>

            <form onSubmit={handleSubmit} className='bg-success1 mt-4'>
              {/* Full Name */}
              <div className='row mb-2'>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_14'>First Name</label>
                <div className='col-lg-8'>
                  {isEditing ? (
                    <input
                      type='text'
                      className='form-control fs_14 border-0 mb-1 ps-0 rounded-0 border_bottom text-capitalize'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  ) : (
                    <span className='Source_Bold fs_15 text_gray text-capitalize'>{user && user.first_name || 'Not Available'}</span>
                  )}
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_14'>Last Name</label>
                <div className='col-lg-8'>
                  {isEditing ? (
                    <input
                      type='text'
                      placeholder='Enter last name'
                      className='form-control fs_14 border-0 mb-1 ps-0 rounded-0 border_bottom text-capitalize'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  ) : (
                    <span className='Source_Bold fs_15 text_gray text-capitalize'>{user && user.last_name || <p className='Source_SemiBold text-secondary m-0 mt-1'>Not Available</p>}</span>
                  )}
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_14'>Email</label>
                <div className='col-lg-8'>
                  {isEditing ? (
                    <input
                      type='text'
                      className='form-control fs_14 border-0 mb-1 ps-0 rounded-0 border_bottom ps-1'
                      value={user && user.email || email}
                      disabled
                    />
                  ) : (
                    <span className='Source_Bold fs_15 text_gray'>{user && user.email || ''}</span>
                  )}
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_14'>Phone Number</label>
                <div className='col-lg-8'>
                  {isEditing ? (
                    <>
                      <input
                        type='number'
                        className='form-control fs_14 border-0 mb-1 ps-0 rounded-0 border_bottom'
                        value={num}
                        placeholder='Enter phone number'
                        onChange={(e) => setNum(e.target.value)}
                      />
                      {err && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err}</p>}
                    </>
                  ) : (
                    <span className='Source_Bold fs_15 text_gray'>{user && user.phone || <p className='Source_SemiBold text-secondary m-0 mt-1'>Not Available</p>}</span>
                  )}
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_14'>Address</label>
                <div className='col-lg-8'>
                  {isEditing ? (
                    <input
                      type='text'
                      className='form-control fs_14 border-0 ps-0 rounded-0 border_bottom'
                      value={address}
                      placeholder='Enter address'
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  ) : (
                    <span className='Source_Bold fs_15 text_gray'>{user && user.address || 'Not Available'}</span>
                  )}
                </div>
              </div>
              <div className={`row mb-2 ${isEditing ? 'mt-3' : ''}`}>
                <label className='col-lg-4 mt-1 Source_SemiBold text-muted text_gray2 fs_15'>Country</label>
                <div className={`col-lg-8 `}>
                  {isEditing ? (
                    <CountriesDrop onChange={setCountry} selected={country} type={'sign'} />
                  ) : (
                    <span className='Source_Bold fs_15 text_gray'>{user && user.countryId?.name || <p className='Source_SemiBold text-secondary m-0 mt-1'>Not Available</p>}</span>
                  )
                  }
                </div>
              </div>

              {isEditing && (
                <div className='d-flex justify-content-end bg-warning1 mt-4'>
                  <button disabled={!isEnable} className={`btn text-white fs_14 ${isEnable ? 'bg_thm' : 'bg_gray2'}`}>
                    {isLoading ? <Spin size='small'/> : 'Save Changes'}
                  </button>
                </div>
              )}

            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
