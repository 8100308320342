import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { CustomLeftArrow } from './CustomArrow'
import Carousel from 'react-multi-carousel'
import { CommonAPI } from './CommonAPI';
import { MemContext } from '../ContextRedux/Context';
import Select from 'react-select'
import { customStyle, useCountryGet } from './CommonFs';
import { Link, useNavigate } from 'react-router-dom';



const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

const responsive_8 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 11,
        slidesToSlide: 11
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 8,
        slidesToSlide: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 6,
        slidesToSlide: 6
    },
    mobile: {
        breakpoint: { max: 767, min: 666 },
        items: 4,
        slidesToSlide: 4
    },
    mobilex: {
        breakpoint: { max: 666, min: 0 },
        items: 4,
        slidesToSlide: 4
    }
};

const responsive = {
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 8,
        slidesToSlide: 8
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 5,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 5,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 767, min: 559 },
        items: 3,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 3
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 2,
        slidesToSlide: 2
    },
};


const CountriesDrop = ({ onChange, selected, type, margin }) => {
    const { userInfo } = useContext(MemContext);
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { country1 } = useCountryGet();


    const filterId = list.filter(item => item.name === country1)?.[0]
    const userCountry = userInfo == null ? filterId?._id : userInfo?.countryId?._id
    // const condition = (type == 'query' || type == 'sign') ? userCountry : ''

    const selectedValue = (selected == '' || selected == undefined || selected == null) ? filterId?._id : selected


    useEffect(() => {
        getList();
    }, [selectedValue]);

    const getList = (id) => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `countryList`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res?.data
            if (selectedValue !== undefined) {
                onChange(selectedValue, 'cID');
                localStorage.setItem('countriesList', JSON.stringify(dt));
            }
            setList(dt)

        } else {
        }
    })

    const options = list.map((item) => ({
        value: item._id,
        label: item.name
    }))

    if (type !== 'sign') {
        options.unshift({
            value: 'TL',
            label: 'All Countries'
        })
    }


    const value = type == 'query' && userInfo !== null ? null : options.find(val => val.value == selectedValue);

    return (
        <div className="w-100 z-5">
            <Select
                options={options}
                className={`form-control z-5 pt-0 bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${margin ? '' : 'mb-2'}`}
                value={value || null}
                placeholder='Select Country'
                styles={customStyle}
                classNamePrefix="custom-select border-0 outline-none"
                menuPortalTarget={document.body}
                onChange={(val) => { onChange(val.value, 'cID'); }} />
        </div>
    )
}

const CurrencyDrop = ({ onChange, selected }) => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getList();
    }, []);

    const getList = (id) => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `currencyList`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res?.data
            // if (selectedValue !== undefined) {
            //     onChange(selectedValue, 'cID');
            //     localStorage.setItem('countriesList', JSON.stringify(dt));
            //     // countries(dt)
            // }
            // onChangePackage(selectedValue, 'kkkkkk')

            setList(dt)

        }
    })

    const options = list.map((item) => ({
        value: item._id,
        label: item.name
    }))



    return (
        <div className="w-100 ">
            <Select
                options={options}
                value={options.find(val => val.value === selected)}
                placeholder='Select'
                styles={{
                    control: (provided) => ({
                        ...provided,
                        height: '35px',
                        minHeight: '35px',
                        borderColor: 'transparent',
                        boxShadow: 'none',
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: 0,
                    }),
                    menuList: (provided) => ({
                        ...provided,
                        padding: 0,
                        fontSize: 12,
                        overflowY: 'scroll',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }),
                }}
                className='form-control pt-0 bg-warning1 mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                classNamePrefix="custom-select px border-0 outline-none"
                menuPortalTarget={document.body}
                onChange={(val) => { onChange(val.value, 'currency'); }}
            />

        </div>
    )
}

const StatesList = ({ data, selected, callback }) => {
    const carouselRef = useRef(null)
    const [id, setId] = useState('');
    const navigate = useNavigate();


    // const handleState = (id) => {
    //     setId(id)
    //     callback(id, 'location')
    // }

    const handleState = (name, id, idLong, type) => {
        const slug = name.replace(/ /g, '-')

        navigate(`/${type}/${slug}/${id}`, { state: { id: idLong, type: type } },)
    }

    return (
        <Carousel
            ref={carouselRef}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={true}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            customTransition="transform 1000ms ease-in-out"
            containerClass="carousel-container"
            itemClass="carousel-item1 d-flex align-items-center px-2 "
            customLeftArrow={
                <CustomLeftArrow
                    direction="left"
                />
            }
            customRightArrow={
                <CustomLeftArrow
                    direction="right"
                />
            }
            dotListClass="custom-dot-list-style"
        >
            {data && data.map((item) => {
                return (
                    <div key={item._id} onClick={() => handleState(item.name, item.id, item._id, 'location')} className={`project-destination states bg-warning mb-1 position-relative rd_10 overflow-hidden ${selected == item._id ? 'border_thm shadow-gray br-2' : ''}`}>
                        {/* <Link to={`/${type}/${slug}/${id}`} className=''> */}
                        <img alt="state" className={`img z-4`} src={`${img_base_url + item.image}`} />
                        <div className='rd_6 hoverthis d-flex justify-content-center align-items-center pointer' style={{
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 10,
                            background: 'linear-gradient(to top, rgba(0,0,0,0.35), rgba(0,0,0,0.35)) ',
                        }}>
                            <text className='Rubik-Regular text-center fs_14 text-white text-capitalize px-1'>{item.name}</text>
                        </div>
                        {/* </Link> */}
                    </div>
                )
            })}
        </Carousel>
    )
}

export { CountriesDrop, CurrencyDrop, StatesList }