import React, { useCallback, useEffect, useState } from 'react'
import { HTMLRender } from '../../Componets/HTMLRender';
import { Col, Row, Spinner } from 'react-bootstrap';
import { dec } from '../../Componets/SecRes';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { openInNewTab } from '../../Componets/CommonFs';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { CommonAPI } from '../../Componets/CommonAPI';
import Carousel from 'react-multi-carousel';
import { Spin } from 'antd';
import SignInModal from '../Auth/SignInModal';
import { Loader } from '../../Componets/Loader';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;


const responsive_8 = {
  desktop1: {
    breakpoint: { max: 2000, min: 1200 },
    items: 4,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 1200, min: 868 },
    items: 3,
    slidesToSlide: 3
  },
  xtablet: {
    breakpoint: { max: 868, min: 567 },
    items: 2,
    slidesToSlide: 2
  },
  mobilex: {
    breakpoint: { max: 567, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const responsive = {
  desktop1: {
    breakpoint: { max: 2000, min: 1150 },
    items: 5,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 1150, min: 828 },
    items: 4,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 828, min: 560 },
    items: 3,
    slidesToSlide: 2
  },
  mobilex: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

function TripsAbout() {
  const { parent, slug, id } = useParams();


  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const types = ['country', 'location', 'state']

  const getDetails = (tagId) => {

    if (!types.includes(parent)) {
      navigate('/not-found', { replace: true });
      return
    }

    setIsLoading1(true)
    CommonAPI({}, 'GET', `locationDetails?id=${id}&type=${parent}`, responseBack)
    CommonAPI({}, 'GET', `topTagsList?type=${parent}&typeId=${id || ''}`, responseBack2)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res?.data, key);
      setData(dec1)

      if (dec1?.data?.name !== slug) {
        navigate(`/${parent}/${dec1?.data?.name}/${id}`)
        setIsLoading1(false)
        return
      }

    } else {
      navigate('/not-found', { replace: true });
    }
    setIsLoading1(false)
  })

  const responseBack2 = useCallback(res => {
    
    if (res.status === true) {
      const dec1 = dec(res?.data, key)

      setTagsList(dec1)
    } else{

    }
  })

  const details = data?.data;

  const goToPkgList = (type) => {
    const pkgHome = localStorage.getItem('pkgHome');
    const pkgHomeParams = JSON.parse(pkgHome)

    const newState = {
      ...pkgHomeParams,
      location: state?.type == 'location' ? state?.id : '',
      month: []
    }

    if (newState?.cID) {
      openInNewTab('/package/list', newState)
    }
  }

  const openAbout = (name, id, idLong, type) => {
    const slug = name.replace(/ /g, '-')
    getDetails(id)
    setTagsList([])
    navigate(`/${type}/${slug}/${id}`, { state: { id: idLong, type: type } })
  }


  return (
    <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 bg-gray' style={{ backgroundColor: '#eef4fd' }}>
      {!isLoading1 && details?.image ? (
        <>
          <div className='w_95 mt-4 mb-4 bg-success1'>
            <div className="position-relative rd_10 w-100 h_520px h-250px-sm" style={{ backgroundImage: `url(${img_base_url + details?.image.replace(' ', '%20')})`, backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
              <div className='rd_10 hoverthis d-flex justify-content-center align-items-center' style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                borderRadius: 10,
                background: 'linear-gradient(to top, rgba(0,0,0,0.10), rgba(0,0,0,0.10)) ',
              }}>
                <span className='Inter-Bold text-center fs_26 text-white text-capitalize px-1 text-shadow'>{details?.name}</span>
              </div>
            </div>


            <Row className='mt-5 w-100 bg-warning1 m-0'>
              <Col className='mb-md-3'>
                <div className='bg-white rd_6 p-3 shadow-slowclean'>
                  <div className='Rubik-SemiBold'>About {details?.name}</div>
                  <div className='divider h_05px mt-2 mb-3'></div>
                  <div className={`content-wrapper Source_Regular text-muted  ${isExpanded ? '' : 'mini-line-1 ten'}`}>
                    <HTMLRender htmlContent={details?.about || ''} isExpanded={true} />
                  </div>
                  <div className='text_thmdark pointer fs_12 Inter-Medium mt-1 text-end w-100' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? ' See Less' : ' See More'}</div>
                </div>
              </Col>

              <Col md={4} lg={4} className=''>
                <div className='rd_6 d-flex align-items-center flex-column bg-white p-3 px-4 shadow-slowclean'>
                  <img src='/images/man_with_lauggage.png' className='img-fluid h_130px w_200px rotate-img-flip' />
                  <p className='m-0 fw-bold fs_16 mt-4'>Couldn't find</p>
                  <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Tired of searching? Simply submit your travel details and receive quotations from multiple verified vendors. Choose suitable quotation from the list and enjoy your journey.</p>

                  <Link to={'/post-travel'}>
                    <button className='bg-gradient-thm btn w-auto mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium px-4' disabled={isLoading}>
                      {isLoading ? <Spinner size='sm' /> : 'Post Your Travel'}
                    </button>
                  </Link>

                </div>
              </Col>
            </Row>

            {tagsList && tagsList?.locations?.length > 0 &&
              <>
                <h2 className="mb-2 Rubik-SemiBold fs_17 mb-4 ms-2 mt-3">Popular Locations {parent == 'country' ? 'in ' + details?.name : ''}</h2>

                <div className='w-100 mt-3'>
                  {isLoading1 ? (
                    <Loader type={'country'} />
                  ) : (
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      responsive={responsive}
                      ssr={true}
                      autoPlay
                      infinite
                      autoPlaySpeed={2500}
                      keyBoardControl={true}
                      customTransition="transform 1000ms ease-in-out"
                      containerClass="carousel-container"
                      itemClass="carousel-item1"
                      customLeftArrow={
                        <CustomLeftArrow
                          direction="left"
                          mt={'mt-30px'}
                        />
                      }
                      customRightArrow={
                        <CustomLeftArrow
                          direction="right"
                          mt={'mt-30px'}
                        />
                      }
                      dotListClass="custom-dot-list-style"
                    >
                      {tagsList?.locations?.map((item, index) => {

                        return (
                          <div key={item._id} className="mb-4 position-relative bg-white rd_30 mx-2-5 " onClick={() => openAbout(item.name, item.id, item._id, 'location')}>
                            <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                            <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                              top: 0,
                              background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                            }}>
                              <div className='Inter-SemiBold fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                            </div>
                          </div>
                        )
                      })}

                    </Carousel>
                  )}
                </div>
              </>
            }

            {data?.recommended?.length > 0 && (
              <>
                <div className='bg-warning1 d-flex align-items-center mt-4-5 me-2 justify-content-between'>
                  <div className="Rubik-SemiBold fs_17 ms-2">Popular Packages in {details?.name}</div>
                  {data?.recommended.length > 0 && (
                    <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('cId')}>
                      <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                      <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                  )}
                </div>


                <Carousel
                  swipeable={true}
                  draggable={true}
                  responsive={responsive_8}
                  ssr={true}
                  autoPlay
                  autoPlaySpeed={2500}
                  keyBoardControl={true}
                  customTransition="transform 1000ms ease-in-out"
                  containerClass="carousel-container mt-2"
                  itemClass="carousel-item1 "
                  customLeftArrow={
                    <CustomLeftArrow
                      direction="left"
                    />
                  }
                  customRightArrow={
                    <CustomLeftArrow
                      direction="right"
                    />
                  }
                  removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                  dotListClass="custom-dot-list-style"
                >
                  {data?.recommended.length > 0 && data?.recommended?.map((item, index) => {
                    const lowPrice = item.discountPrice
                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                    return (
                      <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd_6 mx-2-5 pointer w-xxs-90' style={{ backgroundColor: '#fff' }}>
                        <Link target='_blank' to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                          <img alt='img' src={`${img_base_url + item?.image}`} className='h_150px w-100 rd_4' />

                          <div className='p-2-5'>
                            <div className='Rubik-Medium text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>

                            <div className='d-flex align-items-center justify-content-between mt-4'>
                              <div>
                                <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                {lowPrice != null && lowPrice > 0 && (
                                  <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                )}
                              </div>
                              <div className='book-now border_thmdark brc-dark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_dark_medium'>View Details</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </Carousel>
              </>
            )
            }

          </div>

          <div className='w-100 mt-5 d-flex align-items-center justify-content-center'>
            <div className='w_95 mt-4'>
              {tagsList && tagsList?.states?.length > 0 &&
                <div className='bg-white rd_6 p-3 mb-3'>
                  <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States {parent == 'country' ? 'in ' + details?.name : ''}</h2>

                  {tagsList ? (
                    <div className='d-flex flex-wrap gap-2'>
                      {tagsList && tagsList?.states?.map((it, index) => {
                        return (
                          <div key={it._id} onClick={() => openAbout(it.name, it.id, it._id, 'state')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                            {it.name}
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <Spin className='ms-2 mb-2 thm-spin' />
                  )}
                </div>
              }

              <div className='bg-white rd_6 p-3 mb-5'>
                <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>

                {tagsList ? (
                  <div className='d-flex flex-wrap gap-2'>
                    {tagsList && tagsList?.countries?.map((it, index) => {
                      return (
                        <div key={it._id} onClick={() => openAbout(it.name, it.id, it._id, 'country')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                          {it.name}
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <Spin className='ms-2 mb-2 thm-spin' />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='d-flex align-items-center justify-content-center mb-4 vh-100 w-100' style={{ background: 'rgba(0,0,0,0.2)' }}>
          <Spin className='bg-white rd_10 hw_80px d-flex align-items-center justify-content-center thm-spin' />
        </div>
      )}

      {/* Modal Sigin */}
      <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
    </div>
  )
}

export default TripsAbout