import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GuiderList, imagesPlace } from '../../Componets/Images'
import { FormVehicleModal, OpenImageModal } from '../../Componets/Modal'
import { Link, useLocation } from 'react-router-dom';
import { QuerySpons, RecoGuider, RecoHotel, RecommendedVehicle, RecoPkg } from '../../Componets/Recommended';
import { dec, enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import {HTMLRenderNormal} from '../../Componets/HTMLRender';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function GuiderDetails() {
    const location = useLocation();
    const state = location?.state

    const [open2, setOpen2] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [active, setActive] = useState('about')


    const text = `Bring the kids when you stay at The Westin Sohna Resort & Spa in Sohna and they can enjoy the free kids club. Guests can visit the spa to be pampered with deep-tissue massages, body wraps, or facials, and The Bring the kids when you stay at The Westin Sohna Resort & Spa in Sohna and they can enjoy the free kids club. Living Room, one of 3 restaurants, serves breakfast. Other highlights at this luxurious Bring the kids when you stay at The Westin Sohna Resort & Spa in Sohna and they can enjoy the free kids club. resort include 2 outdoor pools, a poolside bar, and a poolside bar, and a poolside bar, and a 24-hour fitness center. All-inclusive room rates at The Westin Sohna Resort & Spa are available. These rates are higher because they include onsite food and beverages in the room rate (some restrictions may apply). Languages English, Hindi`
    const word = text.split(/\s+/);
    const maxWord = 100

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = () => {
        const data = {
            id: state?.type_id
        }

        const enc1 = enc(data, key);
        setIsLoading(true)
        CommonAPI(enc1, 'POST', `guideDetails`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);
            setData(dec1)
        }
        setIsLoading(false)
    })



    return (
        <div className='min-vh-100 mt-12 pt-4' style={{ backgroundColor: '#eef4fd' }}>
            <Container className='bg-warning1 mt-2'>
                {data.map((item, index) => {
                    return (
                        <>
                            <Row className=''>
                                <Col xs={6} sm={5} md={5} lg={4} className='bg-warning1 d-flex justify-content-center'>
                                    <img src={`${img_base_url + item.image}`} className='rounded-circle shadow-n p-2 bg-white w_200px w-md-200px h-md-200px h_200px' />
                                </Col>

                                <Col xs={12} sm={7} md={7} lg={8} className='bg-primary rd_6 p-3 bg-white d-flex flex-column justify-content-between'>
                                    <div className=''>
                                        <p className='Rubik-SemiBold fs_18 m-0'>{item.firstName || ''} {item.lastName || ''}</p>
                                        <div className='d-flex align-items-center Source_Medium fs_13 text-secondary bg-warning1 mt-2'>
                                            <i className='fas fa-medal'></i>
                                            <p className='p-0 m-0 ms-1'>Exp {item.experience}yrs</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium fs_13 text-secondary bg-warning1 mt-2'>
                                            <i className='fas fa-location-dot'></i>
                                            <p className='p-0 m-0 ms-1'>Guider :- {item.city}</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium fs_13 text-secondary bg-warning1 mt-2'>
                                            <i className='fas fa-coins'></i>
                                            <p className='p-0 m-0 ms-1'>Daily price :- ₹{item.chargesDaily}</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium fs_13 text-secondary bg-warning1 mt-2'>
                                            <i className='fas fa-coins'></i>
                                            <p className='p-0 m-0 ms-1'>Hourly price :- ₹{item.chargesHourly}</p>
                                        </div>
                                        <div className='d-flex align-items-center Source_Medium fs_13 text-secondary bg-warning1 mt-2'>
                                            <i className='fas fa-certificate'></i>
                                            <p className='p-0 m-0 ms-1'>Specialities :- {item.specialty.join(', ')}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end mt-1'>
                                        <div className='pointer p-1 mt-2 rd_50 fs_12 px-3 bg-gradient-thm h-xxs-35px text-white Source_Medium d-inline-block align-items-center justify-content-center' onClick={() => setOpen3(true)}>
                                            Book Now
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <div className='bg-warning1 d-flex mb-3'>
                                <div className={`text_gray Source_SemiBold mx-3 pointer ${active == 'about' ? 'active-underline text_thmdark' : ''}`} onClick={() => setActive('about')}>About guider</div>
                                <div className={`text_gray Source_SemiBold pointer ${active == 'gallery' ? 'active-underline text_thmdark' : ''}`} onClick={() => { setActive('gallery'); }}>Gallery</div>
                            </div>

                            {/* <h2 className="Source_Bold fs_15 mt-5 mb-1">About Guiders</h2> */}
                            {active == 'about' ? (
                                <div className='p-2-5 rd_6 bg-white mt-4'>
                                    <p className='m-0 Source_Regular fs_14 text-secondary'>
                                        <HTMLRenderNormal htmlContent={item.about}/>
                                    </p>
                                </div>
                            ) : (
                                <Row className=" bg-warning1 ">
                                    {item.gallery.map((img, i) => (
                                        <Col xs={4} sm={4} md={3} lg={2} className=" bg-primary1 p-2 border_gray1">
                                            <img
                                                src={`${img_base_url + img}`}
                                                onClick={() => {
                                                    setSelectedImage(img)
                                                    setOpen2(true)
                                                }}
                                                className="w-100 h-100 bg-white shadow-1-strong rounded pointer"
                                                alt="Boat on Calm Water"
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}

                        </>
                    )
                })}

                {/* <h2 className="Source_Bold fs_15 mt-5 mb-1">Recommended Guiders</h2>
                <RecoGuider /> */}

                {/* for trust */}
                <Row className='bg-warning1  p-2 px-0 mt-5 mb-4'>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op1.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">Save up to 30%</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    We will find you the cheapest airport transfers with our verified partners!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op2.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">Professional drivers</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    Experienced and polite drivers with well-serviced, comfortable cabs.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='d-flex border_thmlight rd_6 p-2-5 bg-light-green'>
                            <img src='/icons/op3.svg' alt='f' className='mx-1' />
                            <div className='ms-2'>
                                <h2 className="Source_Bold fs_16 mb-0">24/7 Customer Support</h2>
                                <p className='Source_Regular fs_14 pointer m-0'>
                                    We are always available to help you with your transfer
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Query Spons */}
            <QuerySpons mt={'10%'} />

            <Container>
                {/* <h2 className="Source_Bold fs_15 mt-1 mb-2 mt-5">Shimla Packages</h2>
                <RecoPkg />

                <h2 className="Source_Bold fs_15 mt-1 mb-3">Vehicles for Shimla</h2>
                <RecommendedVehicle />

                <h2 className="Source_Bold fs_15 mt-1 mb-3">Hotels in Shimla</h2>
                <RecoHotel /> */}

                <div className='bg-light p-3 rd_6  mt-3'>
                    <h2 className="Source_Bold fs_15 mb-0">Guider in Rishikesh</h2>
                    <div className='d-flex gap-3 text-center flex-wrap mt-4'>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                            <p className='Source_Regular fs_12 pointer m-0 text-center'>Guider Rishikesh</p>
                        ))}
                    </div>
                </div>

            </Container>

            {/* From */}
            <FormVehicleModal isVisible={open3} onClose={setOpen3} />

            <OpenImageModal isVisible={open2} onClose={setOpen2} img={selectedImage} />
        </div >
    )
}

export default GuiderDetails