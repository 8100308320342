import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useGoogleLogin } from '@react-oauth/google';
import { Spinner } from 'react-bootstrap';
import { MemContext } from '../../ContextRedux/Context';
import { useDispatch } from 'react-redux';
import { svInfo } from '../../ContextRedux/Action';
import { CommonAPI } from '../../Componets/CommonAPI';
import { CountriesDrop } from '../../Componets/Countries';
import { dec, enc } from '../../Componets/SecRes';
import { Spin } from 'antd';
import { tstSuccess } from '../../Componets/HotToast';

const key = process.env.REACT_APP_KEY;
const vendor_url = process.env.REACT_APP_VENDOR_URL;

const SignInModal = ({ isOpen, onClose }) => {
    const { login } = useContext(MemContext);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const [first, setFirst] = useState('');
    const [last, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [conPass, setConPass] = useState('');
    const [country, setCountry] = useState('')
    const [num, setNum] = useState('');
    const [switch1, setSwitch1] = useState('lg');
    const [role, setRole] = useState('user');
    const [switch2, setSwitch2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [type, setType] = useState('know');
    const [otp, setOtp] = useState('');
    const [err, setErr] = useState({})
    const [gDetails, setGDetails] = useState({})
    const [error, setError] = useState('')
    const [send, setSend] = useState(false);
    const [timer, setTimer] = useState(60);

    // for resend
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prev => prev - 1)
            }, 1000)

            return () => clearInterval(interval);
        } else {
            setSend(true)
        }
    }, [timer])

    const currentURL = window.location.pathname;



    const val = () => {
        const err = {};
        if (!/^[^\s@]+@gmail\.com$/.test(email) && type !== 'gAuth') {
            err.mail = 'Invalid email format!'
        }

        if (country == 'TL' && switch1 == 'sin') {
            err.country = "Select ountry!"
        }

        if ((switch1 == 'sin' || switch1 == 'psr' || switch1 == 'lg')) {
            if (!password.trim()) {
                err.pass = "Enter valid password!"
            }

            if (type !== 'gAuth') {
                if (!first.trim() && switch1 == 'sin') {
                    err.name = "Enter valid first name!"
                }
            }

            if (password !== conPass && switch1 !== 'lg') {
                err.pass = "Password doesn't match!"
            }

            if ((num.trim().length < 10 || num.trim().length > 12)  && switch1 == 'sin') {
                err.numb = "Invalid number!"
            }
        }

        if ((switch1 == 'psr' || switch1 == 'ot')) {
            if (otp.length !== 6) {
                err.otp = "Enter valid otp!"
            }
        }

        setErr(err)
        return Object.keys(err).length === 0
    }

    const handleSubmit = (e) => {
        e.preventDefault();


        const gAuth = type == 'gAuth'
        const userData = {
            first_name: gAuth ? gDetails.given_name : first,
            last_name: gAuth ? gDetails.family_name : last,
            email: gAuth ? '' : email,
            phone: num,
            password: password,
            countryId: country,
            tokenId: gAuth ? gDetails.token : ''
        };

        const lg = {
            email: email,
            password: password
        };

        const encryptedData = enc(switch1 == 'lg' ? lg : userData, key)

        if (val()) {
            setIsLoading(true)
            if (gAuth) {
                CommonAPI(encryptedData, 'POST', 'google_signup', responseBack)
            } else {
                CommonAPI(encryptedData, 'POST', switch1 == 'lg' ? 'login' : 'signup', responseBack)
            }
        }
    }
    

    const responseBack = useCallback(res => {
        const msg = res.message;

        if (res.status === true) {
            const dec1 = dec(res.data, key)

            setError('')
            setErr({})
            setConPass('')
            setPassword('')
            setEmail('')
            setFirst('')
            setLastName('')
            setNum('')
            onClose();
            setType('know')
            setSwitch1('lg')
            navigate(currentURL)
            // navigate('/')
            dispatch(svInfo({ user: dec1, tkn: res.token }))
            login(dec1, res.token)

        } else {
            if (msg && msg.includes('Password')) {
                setErr({ pass: msg })
            } else if (msg && msg.includes('Email')) {
                setErr({ mail: msg })
                setErr({ auth: msg })
            } else if (msg && msg.includes('Not-Verified')) {
                setErr({ auth: msg })
            } else {
                setErr({})
            }
        }
        setIsLoading(false)
    })

    // resending
    const handleResend = (e, type) => {
        e.preventDefault();
        const otp2 = { email: email, otp: otp }

        const enc1 = enc(otp2, key);

        setIsLoading(true)
        CommonAPI(enc1, 'POST', 'resentOtp', responseBack3)
    }

    const responseBack3 = useCallback(res => {
        if (res.status === true) {
            setTimer(60);
            setSend(false)
        } else {
        }
        setIsLoading(false)
    })

    const handleVerify = (e, type) => {
        e.preventDefault();
        const data = { email: email }
        const otp2 = { email: email, otp: Number(otp) }

        const sendD = switch1 == 'emailV' ? data : otp2
        const enc1 = enc(sendD, key);



        if (val()) {
            setIsLoading(true)

            if (switch1 == 'emailV') {
                CommonAPI(enc1, 'POST', 'emailCheck', responseBack2)
            } else {
                CommonAPI(enc1, 'POST', 'otpVerify', responseBack2)
            }
        }

    }

    const responseBack2 = useCallback(res => {
        const msg = res.message;

        if (res.status === true) {
            if (switch1 == 'emailV') {
                setSwitch1('ot')
                setSwitch2(false)
            } else {
                setSwitch1('sin')
            }
        } else {
            if (msg && msg.includes('Email')) {
                setErr({ mail: msg })
            } else {
                setErr({ wrong: msg })
            }
        }
        setIsLoading(false)
    })


    const handleLoginSuccess = async (response) => {
        try {
            setIsLoading1(true)
            const { access_token } = response;
            const send = {
                tokenId: access_token,
            }
            const enc1 = enc(send, key);
            CommonAPI(enc1, 'POST', 'google_login', responseBack4)

            const res = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            const data = await res.json();

            setGDetails({ ...data, token: access_token })
        } catch (error) {
        }
    };

    const responseBack4 = useCallback(res => {
        if (res.status === true) {
            if (res.message == 'signup') {
                setSwitch1('sin')
            } else {
                const dec1 = dec(res.data, key)
                onClose()
                setSwitch1('lg')
                setType('know')
                
                dispatch(svInfo({ user: dec1, tkn: res.token }))
                login(dec1, res.token)
                navigate(currentURL)
                // window.location.href = currentURL
            }
        } else {
        }
        setIsLoading1(false)
    })

    const handleLoginError = (error) => {
    };

    const login1 = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError,
    });


    const sss = switch1 == 'emailV' ? 'lg' : switch1 == 'ps' ? 'lg' : 'emailV'

    const inputStyle = {
        border: '1px solid #ccc',
        textAlign: 'center',
        marginRight: '15px',
        marginLeft: '15px',
        fontSize: '16px',
        outline: 'none',
        padding: '5px',
    };

    // for new pass
    const handlePassword = (e) => {
        e.preventDefault();
        if (val()) {
            const data = { email: email }
            const reset = { email: email, otp: Number(otp), newPassword: password }

            const send = switch1 == 'psr' ? reset : data
            const enc1 = enc(send, key)

            setIsLoading(true)
            if (switch1 == 'psr') {
                CommonAPI(enc1, 'POST', 'setnewPassword', responseBack1)
            } else {
                CommonAPI(enc1, 'POST', 'forgotPassword', responseBack1)
            }
        }
    }

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            if (switch1 == 'psr') {
                setOtp('')
                setPassword('')
                setConPass('')
                setSwitch1('lg')
                tstSuccess('Password reset successfully!')
                setSwitch2(false)
            } else {
                setSwitch1('psr')
                setSwitch2(false)
            }
        } else {
            setErr({ otp: res.message })
        }
        setIsLoading(false)
    })



    if (!isOpen) return null;

    return (
        <div className="modal-backdrop z-5">
            <div className="modal-dialog">
                <div className="modal-content bg-warning1" onClick={(e) => e.stopPropagation()}>
                    <div className={`modal-body w-lg-350px w_340px scrollable d-flex  ${switch1 === 'ps' || switch1 === 'ot' ? 'h_350px' : switch1 === 'psr' ? 'h_400px' : switch1 === 'emailV' || switch1 === 'sin' ? 'h_400px' : 'h_520px'}`}>
                        <div className={`p-5  px-4-5 px-xs-4 overflow-hiddena bg-warning1 w-100 ${switch1 === 'ot' ? '' : 'pt-4-5'}`}>
                            <h5 className='Rubik-SemiBold text-center'>{switch1 == 'lg' ? 'Welcome Back' : switch1 == 'ps' ? 'Reset Password' : switch1 === 'psr' ? 'Reset Password' : switch1 === 'ot' ? 'OTP Verification' : 'SignUp'}</h5>

                            <p className='Source_Regular fs_12 text_gray text-center'>{switch1 == 'lg' ? 'Login using email and google' : switch1 == 'ps' ? 'We will send you a code for reset password.' : switch1 === 'psr' ? 'Create a New Password to Access Your Account' : switch1 === 'ot' ? 'Verify Your Identity with OTP' : 'Enter your basic information to create your account.'}</p>

                            {!(switch1 == 'ps' || switch1 == 'psr' || switch1 == 'ot' || switch1 == 'lg') && (
                                <div className='bg-warning1 d-flex mb-3 mt-4-5'>
                                    <div
                                        className={`nav-link-booking text-decoration-none pointer d-flex justify-content-center align-items-center flex-grow-1 fs_14 Rubik-Regular px-2-5 mr_10 p-1 rd_50 text-secondary ${role === 'user' ? 'active' : ''}`}
                                        onClick={() => { setError(); setRole('user') }}
                                    >
                                        <i className="fa-solid fa-user fs_12 mr_10"></i>
                                        User
                                    </div>
                                    <div
                                        className={`nav-link-booking text-decoration-none pointer d-flex justify-content-center align-items-center flex-grow-1 fs_14  Source_Regular px-2-5 mr_10 p-1 rd_50 text-secondary ${role === 'vendor' ? 'active' : ''}`}
                                        onClick={() => switch1 == 'lg' ? (setError(), setRole('vendor')) : window.open(vendor_url, '_blank')}
                                    >
                                        <i className="fa-solid fa-user-tie fs_12 mr_10"></i>
                                        Vendor
                                    </div>
                                </div>
                            )}

                            <form className='mt-5 '>
                                {switch1 !== 'ot' ? (
                                    <>
                                        {/* name */}
                                        {switch1 == 'sin' && type == 'know' &&
                                            <div className='d-flex gap-2'>
                                                <div className="form-group flex-grow-1">
                                                    <input type="text" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="First Name" value={first} onChange={(event) => setFirst(event.target.value)} />
                                                    {err.name && <p className='fs_12 p-0 m-0 text-danger'>{err.name}</p>}

                                                </div>
                                                <div className="form-group bg-primary1 flex-grow-1">
                                                    <input type="text" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Last Name" value={last} onChange={(event) => setLastName(event.target.value)} />
                                                </div>
                                            </div>
                                        }

                                        {/* email */}
                                        {switch1 !== 'psr' && !switch2 && switch1 !== 'sin' &&
                                            <>
                                                <div className='d-flex flex-wrap gap-2'>
                                                    <div className="form-group flex-grow-1">
                                                        <input type="email" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Enter Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                                        {err.mail && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.mail}</p>}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* 3rd row */}
                                        {switch1 == 'sin' &&
                                            <div className='d-flex gap-2 mt-1'>
                                                <div className="form-group w-50 bg-warning1">
                                                    <CountriesDrop onChange={setCountry} selected={country} type={'sign'} />
                                                    {err.country && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.country}</p>}
                                                </div>
                                                <div className="form-group w-50 bg-primary1">
                                                    <input type="number" className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Phone Number" value={num} onChange={(event) => setNum(event.target.value)} />
                                                    {err.numb && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.numb}</p>}
                                                </div>
                                            </div>
                                        }

                                        {/* 4th row */}
                                        {switch1 !== 'ps' && switch1 !== 'emailV' && (
                                            <>
                                                {switch1 == 'psr' && !switch2 && (
                                                    <>
                                                        <div className="form-group flex-grow-1 d-flex justify-content-center align-items-center flex-column">
                                                            <label className='Source_Medium fs_13 text-muted text-start w-100'>Enter OTP</label>
                                                            <OtpInput
                                                                value={otp}
                                                                onChange={setOtp}
                                                                numInputs={6}
                                                                isInputNum={true}
                                                                inputType='number'
                                                                renderInput={(inputProps, index) => (
                                                                    <input
                                                                        {...inputProps}
                                                                        style={inputStyle}
                                                                        key={index}
                                                                        type='tel'
                                                                        className='h_40px w_40px mt-3'
                                                                        autoFocus={index === 0}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {err.otp && <p className='fs_13 mb-4 pt-0 mt-0 text-danger'>{err.otp}</p>}
                                                    </>
                                                )}

                                                <div className={`d-flex gap-2 mt-1 `}>
                                                    <div className="form-group flex-grow-1 mb-0 position-relative">
                                                        <input type={showPass ? 'password' : 'text'} className="form-control fs_13 h_38px mb-1 rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Enter your password" value={password} onChange={(event) => setPassword(event.target.value)} required />
                                                        <i className={`fa-regular fa-eye${!showPass ? '-slash' : ''} position-absolute top-0 pr-0 mt-2-5 me-2 pointer`} onClick={()=> setShowPass(!showPass)}></i>
                                                    </div>
                                                    {(switch1 === 'sin' || switch1 === 'psr') && (
                                                        <div className="form-group flex-grow-1 mb-0">
                                                            <input type="password" className="form-control fs_13 h_38px mb-1 rd_10 border_gray Rubik-Regular text_dark_medium" placeholder="Confirm password" value={conPass} onChange={(event) => setConPass(event.target.value)} required />
                                                        </div>
                                                    )}
                                                </div>
                                                {err.pass && <p className={`fs_13 pt-0 mt-0 text-danger ${switch1 === 'psr' ? 'mb-0 p-0' : 'mb-2'}`}>{err.pass}</p>}
                                            </>
                                        )}
                                        {switch1 == 'ps' && switch2 == true && (
                                            <div className="form-group flex-grow-1 d-flex justify-content-center align-items-center flex-column">
                                                <label className='Source_Medium fs_13 text-muted mb-1 text-start'>OTP</label>
                                                <OtpInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    isInputNum={true}
                                                    renderInput={(inputProps, index) => (
                                                        <input
                                                            {...inputProps}
                                                            style={inputStyle}
                                                            key={index}
                                                            type='tel'
                                                            className='h_40px w_40px mt-3 mb-3'
                                                            autoFocus={index === 0}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}

                                        {/* Forgot */}
                                        {switch1 == 'lg' &&
                                            <p className='Source_Regular fs_12 text-primary pointer' onClick={() => { setSwitch1('ps'); setErr({}); }}>Forgot password?</p>
                                        }
                                        {error && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{error}</p>}
                                    </>
                                ) : (
                                    <div className='d-flex justify-content-center align-items-center flex-column mb-4'>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            isInputNum={true}
                                            inputType='number'
                                            renderInput={(inputProps, index) => (
                                                <input
                                                    {...inputProps}
                                                    style={inputStyle}
                                                    key={index}
                                                    type='tel'
                                                    className='hw_35px'
                                                    autoFocus={index === 0}
                                                />
                                            )}
                                        />
                                        {err.otp && <p className='fs_13 w_92 pt-0 mt-2 text-danger'>{err.otp}</p>}

                                    </div>
                                )}

                                {err.auth && <p className='fs_13 mb-0 pt-0 mt-3 text-danger'>{err.auth}</p>}
                                {err.wrong && <p className='fs_13 ms-4 pt-0 mt-0 mb-2 text-danger'>{err.wrong}</p>}


                                <div className='d-flex justify-content-center'>
                                    <button type="button" className={`btn bg-gradient-thm text-white Source_Medium mt-2 ${switch1 !== 'ot' ? 'w-100' : 'w_90'} ${switch1 == 'psr' ? 'mt-5' : ''} ${type == 'gAuth' ? 'mt-5' : ''}`} disabled={isLoading} onClick={(e) => switch1 == 'ps' || switch1 == 'psr' ? handlePassword(e) : switch1 == 'ot' || switch1 == 'emailV' ? handleVerify(e) : handleSubmit(e)}>
                                        {isLoading ? <Spinner size='sm' /> : switch1 == 'lg' ? 'Login' : switch2 === true ? 'Verify OTP' : switch1 == 'ps' || switch1 === 'emailV' ? 'Send OTP' : switch1 == 'psr' ? 'Reset Password' : switch1 == 'ot' ? 'Verify Now' : 'SignUp'}
                                    </button>
                                </div>
                                {switch1 == 'ot' &&
                                    <p className='Source_Regular fs_12 text_gray text-end mt-2 mr_16'>Didn't receive the OTP? {send ? (
                                        <span
                                            className='text-primary pointer'
                                            onClick={(e) => handleResend(e)}
                                        >
                                            Resend it again.
                                        </span>
                                    ) : (
                                        <span className='text-gray'>
                                            Resend in {timer}s
                                        </span>
                                    )}</p>
                                }
                            </form>
                            {/* BUtton */}


                            {(switch1 == 'lg' || switch1 == 'emailV') && (
                                <>
                                    {switch1 !== 'ps' && switch1 !== 'ot' && switch1 !== 'psr' && (
                                        <>
                                            <div className="or-divider bg-warning1 d-flex justify-content-center  mt-4 mb-3">
                                                <span className='fs_12 Source_Regular text-secondary'>Or</span>
                                            </div>

                                            <div className="bg-warning1 d-flex flex-wrap align-items-center justify-content-around pb-3">
                                                <button className="btn btn-light login-button flex-grow-1 Source_Regular fs_12" onClick={() => { login1(); setType('gAuth'); }}>
                                                    <img src="/icons/search.png" alt="Google logo" className="hw_20px mr_10" />
                                                    Sign In with Google
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {switch1 !== 'ot' && switch1 !== 'psr' &&
                                <>
                                    {switch1 == 'emailV' || switch1 == 'lg' ?
                                        <p className={`Source_Regular fs_12 text_gray text-center mb-2 ${role == 'vendor' ? 'mt-3' : ''}`}>{switch1 == 'emailV' ? 'Already' : "Don't"} have an account? <span className='text-primary pointer' onClick={() => { setSwitch1(sss); setType('know'); setErr({}); setRole('user'); }}>{switch1 == 'emailV' ? 'LogIn' : 'SignUp'}</span></p>
                                        :
                                        <p className='Source_Regular fs_12 text_gray text-center mt-3'>Back to? <span className='text-primary pointer' onClick={() => { setSwitch1('lg'); setType('know'); setSwitch2(false); }}>LogIn</span></p>
                                    }
                                </>
                            }
                        </div>
                        {isLoading1 && (
                            <div className='bg_thmlightdark position-absolute p-3 h-100 rd_10 m-0 w-100 d-flex align-items-center justify-content-center'>
                                <Spin className='bg-white rd_10 hw_80px d-flex align-items-center justify-content-center thm-spin' />
                            </div>
                        )}
                    </div>
                    {!isLoading1 && (
                        <div className='close-button pointer px-3' onClick={() => {
                            onClose()
                            setSwitch1('lg');
                            setType('know');
                            setIsLoading(false)
                        }}>
                            <i className='fa fa-close'></i>
                        </div>
                    )}

                    {switch1 == 'ot' &&
                        <div className='back-button pointer px-3' onClick={() => setSwitch1('emailV')}>
                            <i className='fas fa-arrow-left'></i>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
};

export default SignInModal;
